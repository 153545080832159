<template>
  <YoTable
    ref-specify="FormType3"
    :api-endpoint="apiEndpoint"
    :title-tbl="this.$t('Dashboards') + ' Form Type 3 (T3)'"
    :sub-title-tbl="this.$t('Land Vehicles')"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :disable-edit="disableEdit"
    :multiple-header="multipleHeader"
    :force-disable-crud="true"
    enable-create-form="monitoring-jalur-darat-form-type-3-wizard"
    placeholder-search="LHCode"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT3SLB.vue'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      apiEndpoint: 'form3_land_vehicle',
      disableEdit: true,
      multipleHeader: {
        startFrom: this.$can('manage', 'qrcode') ? 7 : 6,
        content: [
          {
            value: 'QRCODE',
            span: '2',
            align: 'text-center'
          },
          {
            value: 'CLOCK',
            span: '3',
            align: 'text-center'
          },

          {
            value: 'Kendaraan',
            span: '2',
            align: 'text-center'
          },
          {
            value: '',
            span: '2',
            align: 'text-center'
          },
          {
            value: 'Kegiatan',
            span: '2',
            align: 'text-center'
          },
          // {
          //   value: 'Detail Bongkar (B)',
          //   span: '4',
          //   align: 'text-center',
          // },
          // {
          //   value: 'Detail Muat (M)',
          //   span: '4',
          //   align: 'text-center',
          // },
          {
            value: 'SCHEDULE',
            span: '3',
            align: 'text-center'
          }
        ],
        noEnd: false,
        endFrom: this.$can('manage', 'qrcode') ? 8 : 7
      },
      fields: [
        {
          searchModel: 'group.name',
          search: {
            type: 'text'
          },
          key: 'group.name',
          minWidth: '130px',
          label: 'GROUP',
          placeholder: '',
          sortable: true,
          input: { type: 'input-noarea', disabled: true }
        },
        {
          key: 'action_form',
          label: 'Aksi',
          acl:
            this.$can('manage', 'qrcode') ||
            this.$can('MI (LMP)', '') ||
            this.$can('DAS (Cementing)', '') ||
            this.$can('Facility (SLB)', '') ||
            this.$can('RPI', '') ||
            this.$can('ELNUSA (Cementing)', '') ||
            this.$can('SAL', '') ||
            this.$store.state.app.roleName === 'viewer'
        },
        {
          searchModel: 'form_status',
          search: {
            type: 'text'
          },
          key: 'form_status',
          // stickyColumn: true,
          minWidth: '150px',
          label: 'Form Status',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          key: 'bongkar_no_doc',
          label: 'No Dokumen (B)',
          sortable: true,
          // stickyColumn: true,
          input: { type: 'input-noarea' },
          searchModel: 'bongkar_no_doc',
          search: {
            type: 'text'
          }
        },
        {
          key: 'muat_no_doc',
          label: 'No Dokumen (M)',
          sortable: true,
          // stickyColumn: true,
          input: { type: 'input-noarea' },
          searchModel: 'muat_no_doc',
          search: {
            type: 'text'
          }
        },
        {
          key: 'segmen',
          label: 'Segmen',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'segmen',
          search: {
            type: 'text'
          }
        },
        {
          searchModel: 'group.name',
          search: {
            type: 'text'
          },
          key: 'action_qrcode',
          minWidth: '100px',
          label: 'Action',
          sortable: true,
          input: { type: 'input-noarea' }
        },

        {
          searchModel: 'qrcode_status',
          search: {
            type: 'text'
          },
          key: 'qrcode_status',
          minWidth: '150px',
          label: 'QR Status',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          searchModel: 'clock_in_time',
          search: {
            type: 'checkbox',
            option: [
              {
                title: 'Ada',
                value: '1'
              }
            ]
          },
          key: 'clock_in_time',
          minWidth: '180px',
          verticalAlign: 'middle',
          label: 'CLOCK IN',
          sortable: true,
          input: {
            type: 'time',
            format: 'DD MMMM YYYY  hh:mm A',
            disabled: true
          }
        },
        {
          searchModel: 'clock_out_time',
          search: {
            type: 'checkbox',
            option: [
              {
                title: 'Ada',
                value: '1'
              }
            ]
          },
          key: 'clock_out_time',
          minWidth: '180px',
          verticalAlign: 'middle',
          label: 'CLOCK OUT',
          sortable: true,
          input: {
            type: 'time',
            format: 'DD MMMM YYYY  hh:mm A',
            disabled: true
          }
        },
        {
          searchModel: 'diff_clock',
          search: {
            type: 'text'
          },
          key: 'diff_clock',
          label: 'DIFF CLCOK',
          minWidth: '260px',
          sortable: true,
          input: { type: 'input-noarea', disabled: true }
        },

        {
          key: 'dm_land_vehicle.plat_no_pol',
          label: 'Plat No POL',
          placeholder: 'Cth: KT 1503 DR',
          minWidth: '150px',
          sortable: true,
          stickyColumn: true,
          input: { type: 'input-noarea' },
          searchModel: 'dm_land_vehicle.plat_no_pol',
          search: {
            type: 'text'
          }
        },
        {
          key: 'dm_land_vehicle.type_vehicle',
          label: 'Jenis Kendaraan',
          minWidth: '180px',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'dm_land_vehicle.type_vehicle',
          search: {
            type: 'text'
          }
        },
        {
          key: 'dm_personal.full_name',

          label: 'Driver',
          placeholder: 'Sopir',
          minWidth: '190px',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'dm_personal.full_name',
          search: {
            type: 'text'
          }
        },
        {
          key: 'dm_personal.no_ktp',
          label: 'No KTP',
          placeholder: 'No KTP',
          minWidth: '190px',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'dm_personal.no_ktp',
          search: {
            type: 'text'
          }
        },
        {
          searchModel: 'keg_bongkar',
          search: {
            type: 'checkbox',
            option: [
              {
                title: '✓',
                value: '1'
              },
              {
                title: '✕',
                value: '0'
              }
            ]
          },
          key: 'keg_bongkar',
          minWidth: '180px',
          verticalAlign: 'middle',
          label: 'Bongkar',
          sortable: true,
          input: {
            type: 'select',
            boolean: true,
            disabled: true
          }
        },
        {
          searchModel: 'keg_muat',
          search: {
            type: 'checkbox',
            option: [
              {
                title: '✓',
                value: '1'
              },
              {
                title: '✕',
                value: '0'
              }
            ]
          },
          key: 'keg_muat',
          minWidth: '180px',
          verticalAlign: 'middle',
          label: 'Muat',
          sortable: true,
          input: {
            type: 'select',
            boolean: true,
            disabled: true
          }
        },
        // {
        //   key: 'bongkar.jenis_brg',
        //   label: 'Jenis Barang (B)',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'bongkar.jenis_brg',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'bongkar.asal_brg',
        //   label: 'Asal Barang (B)',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'bongkar.asal_brg',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'bongkar.jml_muatan',
        //   label: 'Jumlah Muatan (B)',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'bongkar.jml_muatan',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'bongkar.satuan',
        //   label: 'Satuan (B)',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'bongkar.satuan',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'muat.jenis_brg',
        //   label: 'Jenis Barang (M)',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'muat.jenis_brg',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'muat.tujuan',
        //   label: 'Tujuan Barang (M)',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'muat.tujuan',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'muat.jml_muatan',
        //   label: 'Jumlah Muatan (M)',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'muat.jml_muatan',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'muat.satuan',
        //   label: 'Satuan (M)',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'muat.satuan',
        //   search: {
        //     type: 'text',
        //   },
        // },
        {
          searchModel: 'schedule_in',
          search: {
            type: 'date'
          },
          key: 'schedule_in',
          label: 'SCHEDULE IN',
          sortable: true,
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          }
        },
        {
          searchModel: 'schedule_out',
          search: {
            type: 'date'
          },
          key: 'schedule_out',
          label: 'SCHEDULE OUT',

          sortable: true,
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          }
        },

        {
          searchModel: 'diff_schedule',
          search: {
            type: 'text'
          },
          key: 'diff_schedule',
          label: 'DIFF SCHEDULE',
          minWidth: '260px',
          sortable: true,
          input: { type: 'input-noarea', disabled: true }
        },
        {
          key: 'user.full_name',
          label: 'Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
          searchModel: 'user.full_name',
          search: {
            type: 'text'
          }
        },
        {
          searchModel: 'user.phone',
          search: {
            type: 'number'
          },
          key: 'user.phone',
          minWidth: '170px',
          label: 'Phone of Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true }
        },
        {
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'created_at',
          search: {
            type: 'date'
          }
        },
        {
          key: 'updated_at',
          label: 'Updated AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'updated_at',
          search: {
            type: 'date'
          }
        },
        {
          key: 'form_expired_at',
          label: 'Form Expired AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'form_expired_at',
          search: {
            type: 'date'
          }
        },
        {
          searchModel: 'emergency_action',
          search: {
            type: 'text'
          },
          key: 'emergency_action',
          label: 'Emergency IN OUT',
          sortable: true,
          input: { type: 'input-noarea' },
          acl: this.$can('manage', 'qrcode')
        },
        {
          key: 'id',
          label: 'ID',
          minWidth: '150px',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'id',
          search: {
            type: 'text'
          }
        }
      ]
    }
  },
  computed: {
    newDataTemplate() {
      return {
        // name: '',
        // route: '',
      }
    }
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.form3_land_vehicles
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
