<template>
  <div>
    <!-- Header -->
    <b-row
      align-h="between"
      align-v="center"
      :class="disableEdit ? '' : 'py-1'"
    >
      <b-col sm="4" class="d-flex align-items-center mb-2 mb-sm-0">
        <!-- <h2>{{ title }}</h2> -->
      </b-col>

      <!-- crud action -->
      <b-col sm="8" class="d-flex align-items-center justify-content-sm-end">
        <template v-if="deleteMode">
          <b-button
            class="border-danger mr-1"
            :style="{ backgroundColor: 'white !important' }"
            variant="light"
            @click="
              () => {
                deleteMode = false
                stateOnAdd = false
              }
            "
          >
            <span class="text-danger">Batal</span>
          </b-button>
          <b-button
            id="konfirmasi-hapus-data"
            variant="danger"
            @click="onDeleteToTrigger()"
          >
            Hapus
          </b-button>
        </template>
        <template v-else>
          <b-button
            v-show="
              (!stateOnAdd && disableEdit === false) || disableEdit === null
            "
            id="hapus-data"
            class="mr-1"
            :disabled="forceDisableCrud"
            variant="danger"
            @click="
              () => {
                deleteMode = true
                stateOnAdd = false
              }
            "
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
          <b-button
            v-if="disableEdit === false || disableEdit === null"
            id="tambah-data"
            class="button-tambah mr-1"
            :disabled="forceDisableCrud"
            :variant="stateOnAdd ? 'warning' : 'success'"
            @click="newData(status)"
          >
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />

                {{ stateOnAdd ? 'Klik Tambah Data Lagi' : 'Tambah Data' }}
              </strong></span
            >
          </b-button>
          <b-button
            v-show="stateOnAdd"
            id="save-data"
            class="button-tambah mr-1"
            :disabled="forceDisableCrud"
            variant="success"
            @click="saveButton()"
          >
            <span
              ><strong>
                <feather-icon icon="SaveIcon" />
                Simpan Data
              </strong></span
            >
          </b-button>
          <b-button
            v-show="stateOnAdd"
            id="cancel-data"
            class="button-tambah mr-1"
            :disabled="forceDisableCrud"
            variant="danger"
            @click="cancelSaveButton()"
          >
            <span
              ><strong>
                <feather-icon icon="SaveIcon" />
                Batalkan
              </strong></span
            >
          </b-button>
          <!-- <b-dropdown
            v-show="!stateOnAdd"
            id="status"
            :variant="statusVariant(status)"
          >
            <template #button-content>
              <span v-text="status || 'Tidak ada status'" />
            </template>
            <b-dropdown-header id="dropdown-header-label">
              <small class="text-secondary">Pilih status</small>
            </b-dropdown-header>
            <b-dropdown-item
              variant="info"
              @click.prevent="onUpdateStatus(hasReviewed ? 'Dapat Ditinjau Ulang' : 'Dapat Ditinjau')"
            >
              {{ hasReviewed ? 'Dapat Ditinjau Ulang' : 'Dapat Ditinjau' }}
            </b-dropdown-item>
            <b-dropdown-item
              variant="warning"
              @click.prevent="onUpdateStatus('Proses Pengisian')"
            >
              Proses Pengisian
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="onUpdateStatus('')">
              Tidak ada status
            </b-dropdown-item>
          </b-dropdown> -->
        </template>
      </b-col>
    </b-row>

    <!-- ADVANCE SEARCH ALL COLUMN -->
    <section id="card-actions">
      <b-card-actions title="Advance Search" action-collapse :collapsed="true">
        <template slot="description">
          <span>Click on </span>
          <feather-icon icon="ChevronDownIcon" />
          <span> to see advance search collapse in action.</span>
        </template>
        <b-card-text>
          <b-row>
            <b-col
              v-for="(dataField, index) in advanceFieldSearch()"
              :key="index"
              md="4"
            >
              <b-form-group v-if="'searchModel' in dataField">
                <!-- {{ dataField.searchModel === 'group.name' ? 'tes' : 'no' }} -->
                <div v-if="dataField.search.type !== 'checkbox'">
                  <label>{{ dataField.label }}:</label>
                  <b-form-input
                    v-model="dataField[dataField.searchModel]"
                    :placeholder="
                      dataField.searchModel === 'group.name'
                        ? 'all data , already group by hulu migas'
                        : $t('search')
                    "
                    :type="dataField.search.type"
                    class="d-inline-block"
                    :disabled="
                      dataField.searchModel === 'group.name' ? true : false
                    "
                    @input="
                      advanceSearch($event, dataField[dataField.searchModel])
                    "
                  />
                </div>
                <!-- <div v-if="dataField.searchModel === 'group.name'">
                  <label> test {{ dataField.label }}:</label>
                  <b-form-input
                    v-model="dataField[dataField.searchModel]"
                    :placeholder="$t('search')"
                    :type="dataField.search.type"
                    class="d-inline-block"
                    @input="
                      advanceSearch($event, dataField[dataField.searchModel])
                    "
                  />
                </div> -->
                <!-- warning -->
                <div v-else>
                  <label>{{ dataField.label }}:</label>
                  <v-select
                    v-model="dataField[dataField.searchModel]"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataField.search.option"
                    label="title"
                    @input="advanceSearch"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-actions>
    </section>

    <!-- Table -->
    <b-card body-class="p-0">
      <div class="p-2" align-h="between" align-v="center">
        <b-row>
          <!-- left title -->
          <b-col md="5" lg="9">
            <!-- Page title -->
            <h3 class="mb-2 mb-md-0">
              {{ subTitle }}
              <!-- <span>{{ $t('Admin') }}</span> -->
            </h3>
            <span>Halaman hanya dapat dilihat oleh : </span>
            <feather-icon icon="DatabaseIcon" class="mr-25" />
            <span>{{ infoPageAbility }}</span>
          </b-col>
          <!-- right custom action other page -->
          <b-col>
            <b-button
              v-if="
                enableCreateForm !== null &&
                ($can('manage', 'form1') ||
                  $can('manage', 'form2') ||
                  $can('manage', 'form3')) &&
                $store.state.app.roleName !== 'viewer'
              "
              variant="info"
              :to="{ name: enableCreateForm }"
            >
              <feather-icon icon="FolderPlusIcon" />
              <span class="align-middle ml-50">Buat Form</span>
            </b-button>
          </b-col>
          <!-- right custom action export sidebar -->
          <b-col>
            <b-button
              v-if="enableCreateForm !== null && $can('EXPORT', 'DATA')"
              variant="warning"
              @click="advanceSetData()"
            >
              <feather-icon icon="FolderPlusIcon" />
              <span class="align-middle ml-50">Export XLSX</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <!-- loading table -->
      <b-progress
        v-if="fetchLoading"
        key="info"
        animated
        value="100"
        variant="info"
        :class="'progress-bar-info'"
      />

      <b-table
        id="table-transition"
        :ref="refSpecify || 'YoTable'"
        v-b-tooltip.hover.v-primary
        v-b-tooltip.hover.top="
          disableEdit == false || disableEdit == null
            ? 'Untuk Mengubah Data, Klik 2x Pada Kolomnya'
            : ''
        "
        :fields="customedFields"
        :no-border-collapse="true"
        :items="data"
        :tbody-tr-class="rowClass"
        :tbody-transition-props="transProps"
        sticky-header="700px"
        class="m-0"
        responsive
        hover
        style="table-bordered"
        caption-top
        show-empty
        primary-key="number"
        bordered
      >
        <!-- No Data -->
        <template #empty>
          <p class="mb-0 py-2 text-center">Tidak ada data</p>
        </template>

        <!-- BOTTOM ROW DATA | CALCULATION -->
        <template slot="bottom-row" slot-scope="data">
          <td v-for="(field, i) in data.fields" :key="i">
            <span v-if="i !== 0">
              <strong>{{ calculation[field.key] }}</strong>
            </span>
            <span v-if="i === 1">
              Jumlah Data : {{ pagination.totalItems }}
            </span>
          </td>
        </template>

        <!-- MULTIPLE HEADER -->
        <template v-if="multipleHeader" #thead-top="data">
          <b-tr>
            <b-th :colspan="multipleHeader.startFrom" />
            <multiple-header
              v-for="(header, index) in multipleHeader.content"
              ref="removeJustThis"
              :key="index"
            >
              <b-th
                variant="primary"
                :class="header.align"
                :colspan="header.span"
                v-text="header.value"
              />
            </multiple-header>
            <multiple-header-end
              v-if="multipleHeader.noEnd == false"
              ref="removeJustThisEnd"
            >
              <b-th :colspan="multipleHeader.endFrom" />
            </multiple-header-end>
          </b-tr>
        </template>

        <!-- MULTIPLE TRIPLE HEADER -->
        <template v-else-if="multipleTripleHeader" #thead-top="data">
          <b-tr>
            <b-th :colspan="multipleTripleHeader.startFromFirst" />

            <multiple-header
              v-for="(header, index) in multipleTripleHeader.contentFirst"
              ref="removeJustThis"
              :key="index"
            >
              <b-th
                variant="primary"
                :class="header.align"
                :colspan="header.span"
                v-text="header.value"
              />
            </multiple-header>
            <multiple-header-end
              v-if="multipleTripleHeader.noEndFirst == false"
              ref="removeJustThisEnd"
            >
              <b-th :colspan="multipleTripleHeader.endFromFirst" />
            </multiple-header-end>
          </b-tr>
          <b-tr>
            <b-th :colspan="multipleTripleHeader.startFromSecond" />

            <!-- <b-th variant="secondary">Type 1</b-th> -->
            <multiple-header
              v-for="(header, index) in multipleTripleHeader.contentSecond"
              ref="removeJustThis"
              :key="index"
            >
              <b-th
                variant="primary"
                :class="header.align"
                :colspan="header.span"
                v-text="header.value"
              />
            </multiple-header>
            <multiple-header-end
              v-if="multipleTripleHeader.noEndSecond == false"
              ref="removeJustThisEnd"
            >
              <b-th :colspan="multipleTripleHeader.endFromSecond" />
            </multiple-header-end>
          </b-tr>
        </template>

        <!-- Checkbox Header -->
        <template #head(select)>
          <b-form-checkbox @change="toggleSelectedAll" />
        </template>

        <!-- Cells | ROW DATA tbody tr td -->
        <template #cell()="data">
          <!-- UTILITIES DON'T CHANGE -->
          <!-- FIELD Number -->
          <div v-if="data.field.key == 'number'">
            <p class="mb-0 font-weight-bold" v-text="data.item.number" />
          </div>

          <!-- FIELD SELECT | Checkbox -->
          <div v-else-if="data.field.key == 'select' && deleteMode">
            <b-form-checkbox v-model="data.item.selected" />
          </div>

          <!-- END OF UTILITIES DON'T CHANGE -->

          <!-- MODIFIED TD CONTENT #FIELD_KEY -->

          <!-- #FIELD-SEGMEN -->
          <div v-else-if="data.field.key === 'segmen'">
            <b-badge pill :variant="formStatusVariant(data.item.segmen)">
              <feather-icon icon="MousePointerIcon" class="mr-25" />

              <span v-text="data.item.segmen" />
            </b-badge>
          </div>

          <!-- #FIELD-FORM_STATUS -->
          <div v-else-if="data.field.key === 'form_status'">
            <b-badge
              pill
              :variant="formStatusVariant(data.item.form_status)"
              @click="
                approvedLoadingID === data.item.id
                  ? null
                  : onAprrovedToTrigger(
                      data.item.form_status,
                      data.item.id,
                      data.item
                    )
              "
            >
              <b-spinner
                v-if="approvedLoading && approvedLoadingID === data.item.id"
                small
              />
              <feather-icon icon="MousePointerIcon" class="mr-25" />

              <span v-text="formStatus(data.item.form_status)" />
            </b-badge>
          </div>

          <!-- #FIELD-BONGKAR_NO_DOC -->
          <div v-else-if="data.field.key === 'bongkar_no_doc'">
            <b-badge
              v-if="data.item.keg_bongkar === true"
              v-b-modal.modal-data-preview
              pill
              variant="info"
              @click="
                () => {
                  previewID = data.item.id
                  previewAPI = 'bongkar'
                  previewTitle = `No Document (Bongkar) : ${
                    data.item.bongkar_no_doc || 'Harap Diisi'
                  } , Asal Barang : ${
                    data.item.bongkar_asal_brg || 'Pengguna Tidak Mengisi'
                  }`
                }
              "
            >
              <feather-icon icon="PackageIcon" size="55" />
              <span />
            </b-badge>
            <span class="mb-0 font-weight-bold">
              {{ data.item.bongkar_no_doc }}
            </span>
          </div>

          <!-- #FIELD-MUAT_NO_DOC -->
          <div v-else-if="data.field.key === 'muat_no_doc'">
            <div v-if="data.item.keg_muat === true && !data.item.muat_no_doc">
              <b-badge
                v-if="data.item.keg_muat === true"
                v-b-modal.modal-data-preview
                class="mr-25"
                pill
                variant="info"
                @click="
                  () => {
                    previewID = data.item.id
                    previewAPI = 'muat'
                    previewTitle = `No Document (Muat) : ${
                      data.item.muat_no_doc || 'Harap Diisi'
                    } , Tujuan : ${
                      data.item.muat_tujuan || 'Pengguna Tidak Mengisi'
                    }`
                  }
                "
              >
                <feather-icon icon="PackageIcon" size="55" />
                <span />
              </b-badge>

              <b-badge
                pill
                variant="danger"
                @click="
                  showModalUpdateFORM(
                    data.item.id,
                    'No Dokumen (M)',
                    data.item.muat_no_doc,
                    data.field.key
                  )
                "
              >
                <feather-icon icon="AlertTriangleIcon" class="mr-25" />

                <span class="mb-0 font-weight-bold"> Harap Diisi </span>
              </b-badge>
            </div>
            <div v-else>
              <b-badge
                v-if="data.item.keg_muat === true"
                v-b-modal.modal-data-preview
                pill
                variant="info"
                @click="
                  () => {
                    previewID = data.item.id
                    previewAPI = 'muat'
                    previewTitle = `No Document (Muat) : ${
                      data.item.muat_no_doc || 'Harap Diisi'
                    } , Tujuan : ${
                      data.item.muat_tujuan || 'Pengguna Tidak Mengisi'
                    }`
                  }
                "
              >
                <feather-icon icon="PackageIcon" size="55" />
                <span />
              </b-badge>
              <span class="mb-0 font-weight-bold">
                {{ data.item.muat_no_doc }}
              </span>
            </div>
          </div>

          <!-- #FIELD-emergency_action -->
          <div v-else-if="data.field.key === 'form_expired_at'">
            <p
              v-if="
                tableDateFormatter(
                  data.unformatted,
                  data.field.input.format
                ) === '5 May 5555 - 05:05 AM'
              "
            >
              No Expired ( kendaraan telah masuk )
            </p>
            <p
              v-else
              class="mb-0 font-weight-bold"
              v-text="
                tableDateFormatter(data.unformatted, data.field.input.format)
              "
            />
          </div>
          <div v-else-if="data.field.key === 'emergency_action'">
            <b-badge
              pill
              :variant="formQRCodeVariant(data.item)"
              @click="
                emergencyLoadingID === data.item.id
                  ? null
                  : onInOutToTrigger(data.item.id, data.item)
              "
            >
              <b-spinner
                v-if="emergencyLoading && emergencyLoadingID === data.item.id"
                small
              />
              <feather-icon icon="WatchIcon" class="mr-25" />

              <span v-text="formQRStatus(data.item)" />
            </b-badge>
          </div>

          <!-- #FIELD-action_qrcode -->
          <div v-else-if="data.field.key === 'action_qrcode'">
            <!-- default -->
            <b-badge
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              pill
              :variant="data.item.qrcode ? 'primary' : 'danger'"
              @click="
                showCustomImage(
                  data.item.qrcode,
                  data.item.dm_personal.full_name,
                  data.item.schedule_in
                )
              "
            >
              <feather-icon icon="EyeIcon" class="mr-25" />

              <span>QRCode</span>
            </b-badge>
          </div>

          <!-- #FIELD-qrcode_status -->
          <div v-else-if="data.field.key === 'qrcode_status'">
            <b-badge
              pill
              :variant="qrcodeStatusVariant(data.item.qrcode_status)"
            >
              <feather-icon icon="WatchIcon" class="mr-25" />

              <span v-text="data.item.qrcode_status" />
            </b-badge>
          </div>

          <!-- #FIELD-action_form AKSI -->
          <div v-else-if="data.field.key === 'action_form'">
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              no-caret
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" />
              </template>

              <b-dropdown-item
                v-if="
                  !$can('manage', 'qrcode') &&
                  $store.state.app.roleName !== 'viewer'
                "
                :to="{
                  name: 'monitoring-jalur-darat-form-type-3-wizard',
                  params: { id: data.item.id }
                }"
              >
                <feather-icon icon="TruckIcon" class="text-danger" />
                <span class="text-danger align-middle ml-50"
                  >Lakukan Revisi</span
                >
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.modal-data-history
                @click="() => (historyID = data.item.id)"
              >
                <feather-icon icon="EyeIcon" class="text-warning" />
                <span class="text-warning align-middle ml-50"
                  >History Data</span
                >
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  !$can('manage', 'qrcode') &&
                  $store.state.app.roleName !== 'viewer'
                "
              >
                <feather-icon icon="PackageIcon" class="text-info" />
                <span class="text-info align-middle ml-50"
                  >Buat Operasional</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- #FIELD-group.name -->
          <div v-else-if="data.field.key === 'group.name'">
            <b-badge href="#" variant="info">
              <feather-icon icon="LinkIcon" class="mr-25" />
              <span v-text="data.unformatted === 'SLB' ? 'Hulu Migas' : ''" />
            </b-badge>
          </div>
          <!-- END OF MODIFIED TD CONTENT #FIELD_KEY -->

          <!-- DOUBLE CLICK TO EDIT DATA -->
          <div
            v-else
            :style="{ cursor: isColumnEditable(data) ? 'pointer' : 'text' }"
            @dblclick="forceDisableCrud == true ? '' : onSelectData(data)"
          >
            <!-- FIELD IS ON EDIT #ON_EDIT -->
            <div v-if="data.item.onEdit && isColumnEditable(data)">
              <slot
                v-if="
                  $slots[data.field.columnKey || data.field.key] ||
                  $scopedSlots[data.field.columnKey || data.field.key]
                "
                :name="data.field.key"
                :column="{
                  data,
                  onUpdate,
                  onDelete
                }"
              />
              <div>
                <!-- INPUT != UNDEFINED -->
                <div v-if="data.field.input != undefined">
                  <!-- #ON_EDIT-select-date -->
                  <b-form-input
                    v-if="['select', 'date'].includes(data.field.input.type)"
                    readonly
                    :value="beforeSentDate(data)"
                    :disabled="data.field.input.disabled"
                    :state="
                      data.field.columnKey === 'dm_land_vehicle_id' &&
                      data.item[data.field.columnKey || data.field.key] === 0
                        ? false
                        : data.field.input.options && data.field.input.boolean
                        ? true
                        : true
                    "
                    @click="showSelectModal(data)"
                    @keyup.enter.shift.exact="showSelectModal(data)"
                    @keyup.enter.ctrl.exact="showSelectModal(data)"
                  />
                  <!-- #ON_EDIT-number -->
                  <b-form-input
                    v-else-if="data.field.input.type == 'number'"
                    :ref="`${data.field.key}-${data.item.uuid}`"
                    v-model="data.item[data.field.columnKey || data.field.key]"
                    type="number"
                    :disabled="data.field.input.disabled"
                    :placeholder="`${
                      data.item[data.field.columnKey || data.field.key] ||
                      data.field.placeholder
                    }`"
                    :state="
                      data.item[data.field.columnKey || data.field.key] === ''
                        ? false
                        : true
                    "
                    @keyup.delete="
                      (event) => onDelete(data.item.id, true, event)
                    "
                  />

                  <!-- #ON_EDIT-tag-DESC MULTIPLE VALUE -->
                  <b-form-tags
                    v-else-if="data.field.input.type == 'tag'"
                    v-model="data.item[data.field.columnKey || data.field.key]"
                    :input-id="`tag-${data.uuid}`"
                    :input-attrs="{
                      'aria-describedby': 'tags-remove-on-delete-help'
                    }"
                    separator=","
                    placeholder="1. DATA A, 2. DATA B"
                    remove-on-delete
                    no-add-on-enter
                  />

                  <!-- #ON_EDIT-number-append_DESC append and pepend NUMBER-APPEND-->
                  <b-input-group
                    v-else-if="data.field.input.type == 'number-append'"
                    :append="data.field.input.append"
                  >
                    <b-form-input
                      :ref="`${data.field.key}-${data.item.uuid}`"
                      v-model="
                        data.item[data.field.columnKey || data.field.key]
                      "
                      type="number"
                      required
                      :disabled="data.field.input.disabled"
                      :state="
                        data.item[data.field.columnKey || data.field.key] === ''
                          ? false
                          : true
                      "
                      :placeholder="`${data.field.placeholder}`"
                      @keyup.delete="
                        (event) => onDelete(data.item.id, true, event)
                      "
                    />
                  </b-input-group>

                  <!-- #ON_EDIT-currency_DESC append and pepend NUMBER-APPEND-->
                  <b-input-group
                    v-else-if="data.field.input.type == 'currency'"
                    :append="data.field.input.append"
                  >
                    <currency-input
                      :ref="`${data.field.key}-${data.item.uuid}`"
                      v-model="
                        data.item[data.field.columnKey || data.field.key]
                      "
                      :disabled="data.field.input.disabled"
                      :state="
                        data.item[data.field.columnKey || data.field.key] === ''
                          ? false
                          : true
                      "
                      :placeholder="`${
                        data.item[data.field.columnKey || data.field.key]
                      }`"
                      :options="{
                        currency: 'IDR',
                        locale: 'id-ID',
                        currencyDisplay: 'hidden',
                        autoDecimalDigits: false,
                        precision: 2
                      }"
                      @keyup.delete="
                        (event) => onDelete(data.item.id, true, event)
                      "
                    />
                  </b-input-group>

                  <!-- #ON_EDIT-input-noarea_DESC INPUT NORMAL  -->
                  <b-form-input
                    v-else-if="data.field.input.type == 'input-noarea'"
                    :ref="`${data.field.key}-${data.item.uuid}`"
                    v-model="data.item[data.field.columnKey || data.field.key]"
                    type="text"
                    :disabled="data.field.input.disabled"
                    :placeholder="data.field.placeholder"
                    :value="data.unformatted"
                    :state="
                      data.item[data.field.columnKey || data.field.key] === ''
                        ? false
                        : true
                    "
                    @keyup.delete="
                      (event) => onDelete(data.item.id, true, event)
                    "
                  />

                  <!-- #ON_EDIT-else or text _DESC INPUT AREA   -->
                  <b-form-textarea
                    v-else
                    :ref="`${data.field.key}-${data.item.uuid}`"
                    v-model="data.item[data.field.columnKey || data.field.key]"
                    type="text"
                    :disabled="data.field.input.disabled"
                    :placeholder="data.field.placeholder"
                    :value="data.unformatted"
                    :state="
                      data.item[data.field.columnKey || data.field.key] === ''
                        ? false
                        : true
                    "
                    @keyup.delete="
                      (event) => onDelete(data.item.id, true, event)
                    "
                  />
                </div>
              </div>
            </div>
            <!-- END OF FIELD IS ON EDIT #ON_EDIT -->

            <!-- FIELD IS ON VIEW #ON_VIEW DATA FORMATTED-->
            <div v-else>
              <!-- DO NOT MODIFY -->
              <div v-if="data.unformatted !== ''">
                <!-- input: {
                        type: 'select',
                        boolean: true,
                        disabled: true,
                      }, -->

                <div
                  v-if="
                    'input' in data.field &&
                    'boolean' in data.field.input &&
                    data.field.input.boolean === true
                  "
                >
                  <span v-text="data.unformatted == '1' ? '✓' : '✕'" />
                </div>
                <!-- #FIELD-user.full_name -->
                <div v-else-if="data.field.key === 'user.full_name'">
                  <span
                    v-text="
                      `${data.item.user.full_name} (${data.item.user.perusahaan})`
                    "
                  />
                </div>
                <!-- input: {
                  type: 'date',
                  format: 'D MMMM YYYY - hh:mm A',
                  disabled: true,
                }, -->
                <div
                  v-else-if="
                    'type' in data.field.input &&
                    data.field.input.type === 'date'
                  "
                >
                  <p
                    class="mb-0 font-weight-bold"
                    v-text="
                      tableDateFormatter(
                        data.unformatted,
                        data.field.input.format
                      )
                    "
                  />
                </div>
                <!-- input: {
                  type: 'time',
                  format: 'DD MMMM YYYY  hh:mm A',
                  disabled: true,
                }, -->
                <div
                  v-else-if="
                    'type' in data.field.input &&
                    data.field.input.type === 'time'
                  "
                >
                  <p class="mb-0 font-weight-bold">
                    <span
                      v-text="
                        tableTimeFormatter(
                          data.unformatted,
                          data.field.input.format
                        )
                      "
                    />
                    <feather-icon
                      :icon="
                        tableTimeFormatter(
                          data.unformatted,
                          data.field.input.format
                        )
                          .toString()
                          .includes('PM')
                          ? 'MoonIcon'
                          : 'SunIcon'
                      "
                      class="ml-25"
                    />
                  </p>
                </div>

                <!-- #ON_VIEW-tag -->
                <div v-else-if="data.field.input.type === 'tag'">
                  <b-form-tags
                    v-model="data.unformatted"
                    :input-id="`tag-${data.uuid}`"
                    :input-attrs="{
                      'aria-describedby': 'tags-remove-on-delete-help'
                    }"
                    separator=","
                    placeholder=""
                    disabled="true"
                    remove-on-delete
                    no-add-on-enter
                  />
                </div>
                <!-- END OF DO NOT MODIFY -->

                <div v-else>
                  <span
                    class="mb-0 font-weight-bold"
                    v-text="data.unformatted"
                  />
                </div>
                <!-- END OF DO NOT MODIFY -->
              </div>
              <span v-else v-text="'-'" />
            </div>
          </div>
        </template>
      </b-table>
      <!-- </overlay-loading> -->

      <!-- Footer -->
      <b-card-footer>
        <b-row align-h="between" align-v="center">
          <b-col sm="12" md="2" class="mb-2 mb-sm-0">
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <!-- <b-col
            sm="4"
            md="4"
            class="mb-2 mb-sm-0"
          >
            <h6>

              <b-badge
                href="#"
                variant="primary"
              >
                <feather-icon
                  :icon="bottomIcon"
                  class="mr-25"
                />
                <span
                  v-text="bottomLabel"
                />
              </b-badge>
            </h6>

          </b-col> -->
          <b-col
            sm="12"
            md="2"
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <!-- ALL OF MODAL HERE -->

    <!-- Select Modal #ON_EDIT_MODAL SELECT | DATE -->
    <b-modal
      :id="refSpecify + '_select-modal'"
      title="Pilihan"
      body-class="py-2"
      hide-footer
      :no-enforce-focus="true"
      @show="selectModalOpen"
    >
      <label for="pilihan" class="mb-1">{{ selectModal.label }}</label>
      <b-form-group>
        <v-select
          v-if="selectModal.type == 'select'"
          id="pilihan"
          ref="select"
          v-model="selectModal.value"
          :options="selectModal.options"
          :reduce="selectModal.reduce"
          @search="(keyword, loading) => fetchOptions(keyword, loading)"
        >
          <span slot="no-options">{{ this.$t('no_data') }}</span>
        </v-select>
        <flat-pickr
          v-else-if="selectModal.type == 'date'"
          v-model="selectModal.value"
          class="form-control"
          static="true"
          :config="dpconfig"
        />
      </b-form-group>
    </b-modal>

    <!-- Hapus Modal #ON_DELETE_MODAL -->
    <b-modal
      id="delete"
      title="Hapus"
      cancel-variant="secondary"
      cancel-title="Tidak"
      ok-title="Iya"
      ok-variant="danger"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      centered
      @ok="onDeleteToSelected"
    >
      <b-card-text class="text-center">
        <h4 class="mb-0">Hapus data ?</h4>
        <br />
        <div
          v-for="item in data.filter((data) => data.selected)"
          :key="item.number"
        >
          # {{ item.number }},
        </div>
      </b-card-text>
    </b-modal>

    <!-- Update Modal #ON_UPDATE SINGLE COLUMN  -->
    <!-- select 2 demo -->
    <b-modal
      :id="refSpecify + '_update_form'"
      title="Update Form | Harap Segera di lengkapi"
      ok-title="Update"
      ok-variant="success"
      cancel-variant="outline-secondary"
      size="lg"
      @ok="submitModalUpdateFORM"
    >
      <b-form>
        <b-form-group
          :label="updateFormModal.label"
          :label-for="updateFormModal.label"
        >
          <b-form-input
            :id="updateFormModal.label"
            v-model="updateFormModal.value"
            :placeholder="updateFormModal.label"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Sidabar Export To EXCEL -->
    <ExportXlsx
      :is-export-sidebar-active.sync="isExportSidebarActive"
      :api-genereate-excel="apiEndpoint + '/submit/excel'"
      :backend-url="BACKEND_URL"
    />

    <!-- Modal History Data -->
    <DataHistoryM
      v-if="historyID"
      id="modal-data-history"
      ref="modalhistory"
      :read-history-i-d="historyID"
      :ref-specify="refSpecify + '_history'"
      :api-endpoint="apiEndpoint"
      :fields="
        fields.filter(
          (e) => e.key !== 'action_form' && e.key !== 'emergency_action'
        )
      "
      :disable-edit="disableEdit"
      :force-disable-crud="true"
    />

    <!-- Preview Modal #ON_VIEW_MODAL preview hasMany / hasOne -->
    <PreviewModal
      v-if="previewID"
      id="modal-data-preview"
      ref="previewModal"
      :title-tbl="previewTitle"
      :read-history-i-d="previewID"
      :ref-specify="refSpecify + '_preview'"
      :api-endpoint="previewAPI"
      :fields="previewField"
      :disable-edit="disableEdit"
      :force-disable-crud="true"
    />

    <!-- END OF ALL OF MODAL HERE -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardFooter,
  BCardText,
  BTable,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BPagination,
  BFormSelect,
  BModal,
  BFormCheckbox,
  VBTooltip,
  BBadge,
  VBPopover,
  BIcon,
  BIconStopwatch
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import OverlayLoading from '@views/components/OverlayLoading.vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import CurrencyInput from '@views/pages/components/CurrencyInput.vue'
import { saveAs } from 'file-saver'
import { ref, onUnmounted } from '@vue/composition-api'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData, getStorageAdvanceSearch } from '@/auth/utils'
import ExportXlsx from './Export.vue'
import DataHistoryM from './history/DataHistoryM.vue'
import PreviewModal from './previewModal/PreviewModal.vue'

export default {
  name: 'YoTable',
  components: {
    DataHistoryM,
    PreviewModal,
    ExportXlsx,
    BBadge,
    BCardActions,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardFooter,
    BCardText,
    BTable,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BPagination,
    BFormSelect,
    BModal,
    BFormCheckbox,
    OverlayLoading,
    VSelect,
    Indonesian,
    CurrencyInput,
    BIcon,
    BIconStopwatch
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple
  },
  props: {
    disableEdit: {
      type: Boolean,
      required: false,
      default: null
    },
    titleTbl: String,
    subTitleTbl: String,
    apiEndpoint: String,
    placeholderSearch: String,
    fields: Array,
    newDataTemplate: Object,
    resolveFetchResponse: Function,
    resolveFetchUrl: Function,
    bottomLabel: {
      type: String,
      required: false,
      default: null
    },
    infoPageAbility: {
      type: String,
      required: false,
      default: null
    },
    bottomIcon: {
      type: String,
      required: false,
      default: ''
    },
    addPropToData: {
      type: Function,
      required: false,
      default: null
    },
    createUrl: {
      type: String,
      required: false,
      default: null
    },
    updateUrl: {
      type: String,
      required: false,
      default: null
    },
    deleteUrl: {
      type: String,
      required: false,
      default: null
    },
    singleExport: {
      type: String,
      required: false,
      default: null
    },
    multipleHeader: {
      type: Object,
      required: false,
      default: null
    },
    multipleTripleHeader: {
      type: Object,
      required: false,
      default: null
    },
    isCalculationEnable: {
      type: Boolean,
      required: false,
      default: null
    },
    enableCreateForm: {
      type: String,
      required: false,
      default: null
    },
    forceDisableCrud: {
      type: Boolean,
      required: false,
      default: null
    },
    enableMultipleSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholderSearchSecond: {
      type: String,
      required: false,
      default: 'YoTable'
    },
    refSpecify: {
      type: String,
      required: false,
      default: 'YoTable'
    }
  },
  data() {
    return {
      previewAPI: '',
      historyID: null,
      previewID: null,
      previewTitle: '',
      previewField: [
        {
          key: 'jenis_brg',
          label: 'Jenis Barang',
          sortable: true,
          // stickyColumn: true,
          input: { type: 'input-noarea' },
          searchModel: 'jenis_brg',
          search: {
            type: 'text'
          }
        },
        {
          key: 'jml_muatan',
          label: 'Jumlah Muatan',
          sortable: true,
          // stickyColumn: true,
          input: { type: 'input-noarea' },
          searchModel: 'jml_muatan',
          search: {
            type: 'text'
          }
        },
        {
          key: 'satuan',
          label: 'Satuan',
          sortable: true,
          // stickyColumn: true,
          input: { type: 'input-noarea' },
          searchModel: 'satuan',
          search: {
            type: 'text'
          }
        }
      ],
      preventTwiceRender: false,
      today: moment().format('yyyy-MM-DD HH:mm:ss'),
      isExportSidebarActive: false,
      registrationToken: '',
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
      dpconfig: {
        // default
        wrap: true,
        altInput: true,
        locale: Indonesian,
        defaultDate: 'today'
      },
      transProps: {
        name: 'flip-list'
      },
      allRole: this.$can('manage', 'all'),
      dataMaster: null,
      // lkpsTableId: this.$route.params.lkps_table_id,
      deleteMode: false,
      hasReviewed: false,

      // newData temp
      numberTemp: 0,

      // data
      hello: '',
      data: [],
      dataAdvanceSearch: [],
      updateFormModal: {
        id: null,
        label: '',
        placeholder: '',
        value: '',
        payload: null
      },
      pagination: {
        amountOptions: [5, 7, 15, 35, 55, 75, 100],
        selectedAmount: 7,
        totalItems: 0,
        currentPage: 1
      },
      searchKeyword: '',
      advanceSearchKeyword: '',
      timeoutDebounce: null,

      // select modal
      selectModal: {
        type: '',
        boolean: true,
        label: '',
        field: '',
        value: '',
        options: [],
        reduce: null,
        uuid: '',
        format: ''
      },

      // loading
      fetchLoading: false,
      isFirst: false,
      calculation: {},

      // add save button
      stateOnAdd: false,
      tempSearch: {},
      tempSearchConcenate: '',

      // loading emergency button
      emergencyLoading: false,
      emergencyLoadingID: undefined,
      approvedLoading: false,
      approvedLoadingID: undefined,

      userData: null
    }
  },
  computed: {
    qrcodeStatusVariant() {
      return (any) => this.$variantBadge[any]
    },
    statusVariant() {
      return (any) => this.$variantDropDown[any]
    },
    formStatusVariant() {
      return (any) => this.$formStatusVariant[any]
    },
    formStatus() {
      return (any) => this.$formStatus[any]
    },
    title() {
      if (this.titleTbl) {
        return this.titleTbl
      }
      return null
    },
    subTitle() {
      if (this.subTitleTbl) {
        return this.subTitleTbl
      }
      return null
    },
    status() {
      if (this.dataMaster) {
        return this.dataMaster.status
      }
      return null
    },
    customedFields() {
      const fields = this.deleteMode
        ? [
            { key: 'select', label: '' },
            { key: 'number', label: '#', sortable: true },
            ...this.fields
          ]
        : [{ key: 'number', label: '#', sortable: true }, ...this.fields]

      return fields.map((field) => {
        if (field.hidden === true) {
          field.thClass = 'd-none'
          field.tdClass = 'd-none'
        }

        if (field.key !== 'number' && field.key !== 'select') {
          field.thStyle = {
            minWidth: '300px',
            textAlign: 'left',
            verticalAlign: 'middle'
          }
        }

        if (field.key === 'number' || field.key === 'action_form') {
          field.thStyle = { verticalAlign: 'middle' }
          field.thClass = 'number'
        }
        if ('minWidth' in field) {
          field.thStyle = {
            minWidth: `${field.minWidth}`,
            textAlign: 'left',
            verticalAlign: 'middle'
          }
        }
        if (
          field.key === 'group.name' &&
          this.allRole !== true &&
          this.$can('manage', 'qrcode') !== true &&
          this.$store.state.app.roleName !== 'viewer'
        ) {
          field = null
        }
        try {
          if (field.acl === false) {
            if (this.$can('manage', 'all') !== true) {
              field = null
            }
          }
        } catch (err) {
          //
        }
        return field
      })
    }
  },
  watch: {
    '$store.state.app.watchNotificationClockIN': function () {
      if (
        this.refSpecify === 'FormType1' ||
        this.refSpecify === 'FormType2' ||
        this.refSpecify === 'FormType3'
      ) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
      }
    },
    '$store.state.app.watchNotification': function () {
      if (
        this.refSpecify === 'FormType1' ||
        this.refSpecify === 'FormType2' ||
        this.refSpecify === 'FormType3'
      ) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
      }
    },
    'pagination.currentPage': function () {
      if (this.advanceSearchKeyword) {
        this.timeoutDebounce = setTimeout(
          () => this.advanceSearchFetch(this.advanceSearchKeyword),
          300
        )
      } else {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
      }
    },
    'pagination.selectedAmount': function () {
      if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
      this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
    },
    searchKeyword(keyword) {
      if (keyword.length <= 0 || keyword.length >= 2) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetch(keyword), 300)
      }
    },
    'selectModal.value': function (value) {
      this.data = this.data.map((item) => {
        if (item.uuid === this.selectModal.uuid) {
          item[this.selectModal.field.columnKey || this.selectModal.field.key] =
            value

          const { format } = this.selectModal.field.input
          if (format !== undefined) {
            if (moment(value, format, true).isValid()) {
              item[
                this.selectModal.field.columnKey || this.selectModal.field.key
              ] = moment(value, format).format(format)
            }
          }

          // data master dosen

          const option = this.selectModal.options.find(
            (option) => option.value == this.selectModal.value
          )

          if (option) {
            const path = this.selectModal.field.key.split('.')
            path.reduce((prev, curr, index, path) => {
              try {
                if (prev != null) {
                  if (prev && index === path.length - 1) {
                    prev[curr] = option.label
                    if (this.selectModal.field.input.boolean === true) {
                      prev[curr] = option.value
                    }
                    return prev[curr]
                  }
                  prev[curr] = option
                }
                return prev[curr]
              } catch (error) {}
            }, item)
          }
          if (value === null || value === '') {
            const path = this.selectModal.field.key.split('.')
            path.reduce((prev, curr, index, path) => {
              prev[curr] = null
            }, item)
          }
        }
        return item
      })

      this.$bvModal.hide(`${this.refSpecify}_select-modal`)
    }
  },
  created() {
    // this.listeningFirebase()
    // console.log('api', this.apiEndPoint)
    this.init()
    const userData = getUserData()
    this.userData = userData
    this.fetch()
    if (this.$store.state.app.roleName !== 'viewer') {
      document.addEventListener('keydown', this.saveChanges)
    }
  },
  mounted() {
    // document.body.style.zoom = '90%'
    this.$nextTick(() => {
      this.removeParentElement()
    })
  },
  destroyed() {
    // document.body.style.zoom = '90%'
    if (this.$store.state.app.roleName !== 'viewer') {
      document.removeEventListener('keydown', this.saveChanges)
    }
  },
  methods: {
    // TODO: UTILS
    init() {
      this.$forceUpdate()
      useJwt.http
        .get('app_update')
        .then((response) => {
          const { update_status, web_msg } = response.data.app_updates
          console.log('update_status:', update_status)
          if (update_status === this.$forceToRefresh) {
            try {
              this.$swal({
                title: '',
                html: web_msg,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/pages/not-authorized.svg'),
                // imageWidth: 402,
                // imageHeight: 472,
                imageAlt: 'system_update',
                confirmButtonText: 'CLOSE',
                showCancelButton: false,
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-outline-danger ml-1'
                },

                buttonsStyling: false
              }).then(async () => {
                useJwt.http.put('app_update/next/logout').then(() => {
                  this.$router.go()
                })
              })
            } catch (err) {
              console.log('swal', err)
            }
          } else if (update_status === this.$forceToLogout) {
            console.log('user automaticaly logout:', update_status)

            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem('userData')

            // Redirect to login page
            // this.$router.go()
            this.$router.push({ name: 'auth-login' })
          }
        })
        .catch((err) => {
          //
        })
    },
    moment,
    rowClass(item, type) {
      const colorClass = 'table-danger'
      if (!item || type !== 'row') {
        return
      }

      // eslint-disable-next-line consistent-return
      if (item.qrcode_status === 'Expired') {
        return colorClass
      }
    },
    formQRCodeVariant(item) {
      if (item.qrcode_status === 'Expired') {
        return 'danger'
      }
      if (item.clock_in_time === null && item.clock_out_time === null) {
        return 'success'
      }
      if (item.clock_in_time !== null && item.clock_out_time !== null) {
        return 'info'
      }
      return 'warning'
    },
    formQRStatus(item) {
      if (item.qrcode_status === 'Expired') {
        return 'Expired'
      }
      if (item.clock_in_time === null && item.clock_out_time === null) {
        return 'Clock IN'
      }
      if (item.clock_in_time !== null && item.clock_out_time !== null) {
        return 'Used'
      }
      return 'Clock OUT'
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
    // listeningFirebase() {
    //   try {
    //     firebase
    //       .messaging()
    //       .requestPermission()
    //       .then(() => {
    //
    //         firebase
    //           .messaging()
    //           .getToken()
    //           .then(token => {
    //             window.
    //             this.registrationToken = token
    //             this.receiveMessage()
    //           })
    //       })
    //       .catch(err => {
    //
    //       })
    //   } catch (e) {
    //
    //   }
    // },
    // async receiveMessage() {
    //   try {
    //     firebase.messaging().onMessage(payload => {
    //
    //       if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
    //       this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
    //     })
    //   } catch (e) {
    //
    //   }
    // },
    async showCustomImage(fileName, fullName, scheduleIn) {
      const imageURL = `${this.BACKEND_URL}/QRCode/${fileName}`
      const fileNames = `${fullName}_${moment(scheduleIn).format(
        'DD-MM-YYYY hh:mm a'
      )}.png`
      console.log(fileNames)
      this.$swal({
        title: 'Sweet!',
        text: 'Semoga hari anda menyenangkan.',
        // eslint-disable-next-line global-require
        imageUrl: imageURL,
        // imageWidth: 402,
        // imageHeight: 472,
        imageAlt: 'QRCode',
        confirmButtonText: 'Download',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },

        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          saveAs(imageURL, fileNames)
        }
      })
    },
    advanceSetData() {
      this.isExportSidebarActive = true
      // this.isSidebarActive = true
    },
    advanceFieldSearch() {
      const field = this.customedFields
        .filter((data) => data != null)
        .filter((data) => data.key !== 'number')
        .filter(
          (data) =>
            data.key !== 'action_form' &&
            data.key !== 'action_qrcode' &&
            data.key !== 'select'
        )

      this.tempSearch = field.map((data) => ({
        whereColumn: data.key,
        keyword: '',
        onUpdate: false
      }))
      return field
    },
    advanceSearch(val, args) {
      let searchFromMapping = ''
      this.advanceFieldSearch().map((el, index) => {
        // console.log('val', typeof val, 'key: ', el.key, ' value :', el[`${el.key}`])
        if (
          el[`${el.key}`] !== undefined &&
          el[`${el.key}`] !== null &&
          el[`${el.key}`] !== '' &&
          el[`${el.key}`] !== 'undefined'
        ) {
          let value = ''
          value = el[`${el.key}`]?.value
          if (value === undefined) {
            value = el[`${el.key}`]
          }
          searchFromMapping = `${searchFromMapping + el.key}=${value}&`
        }
      })
      searchFromMapping = searchFromMapping.slice(0, -1)
      this.advanceSearchKeyword = searchFromMapping
      this.timeoutDebounce = setTimeout(
        () => this.advanceSearchFetch(searchFromMapping),
        300
      )
    },
    async advanceSearchFetch(keyword) {
      //
      const url = this.resolveFetchUrl({ pagination: this.pagination })
      useJwt.http
        .get(url.url, { params: { ...url.query, keyword } })
        .then((response) => {
          let number =
            this.pagination.currentPage * this.pagination.selectedAmount -
            this.pagination.selectedAmount +
            1
          const result = this.resolveFetchResponse(response).map((list) => ({
            ...list,
            selected: false,
            ...(this.addPropToData ? this.addPropToData(list) : {}),
            number: number++,
            uuid: uuidv4(),
            onEdit: false
          }))
          //
          this.dataAdvanceSearch = result
          /**
           * this.data = result
           * jika line code itu dimatikan , this.tempSearch data nya masih ada
           * jika line code dinyalakan,  this.tempSearch data nya hilang
           *
           * jika data hilang maka keyword jadi single search, aneh
           */
          // this.$nextTick(() => {
          this.data = result
          // })
          /**
           *
           * expected : result : created_by=john&updated_by=doe // if this.data has comment
           * unExpected : result : updated_by=doe // if this.data = result is active
           */
          //
          //
          this.pagination.totalItems = response.data.total_items
        })
        .catch((e) => {})
        .finally(() => {
          // this.data = []
        })
    },
    showModalUpdateFORM(id, label, value, key) {
      if (this.$store.state.app.roleName === 'viewer') {
        this.$swal({
          position: 'top-end',
          icon: 'warning',
          title: 'You dont have permission',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        return
      }
      this.$bvModal.show(`${this.refSpecify}_update_form`)
      this.updateFormModal.id = id
      this.updateFormModal.label = label
      this.updateFormModal.placeholder = label
      this.updateFormModal.value = value
      this.updateFormModal.payload = key
    },
    async submitModalUpdateFORM() {
      const { id, payload, value } = this.updateFormModal
      const data = {
        [payload]: value
      }
      this.fetchLoading = true
      await useJwt.http
        .put(`${this.apiEndpoint}/${id}`, data)
        .then(() => {
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    cancelSaveButton() {
      this.numberTemp = 0
      this.stateOnAdd = false
      this.fetch()
    },
    saveButton() {
      this.numberTemp = 0
      //
      this.updateAllData()
    },
    // resetStatus() {
    //   if (this.status.includes('Dapat Ditinjau', 'Selesai', 'Perlu Diperbaiki')) {
    //     this.onUpdateStatus('Proses Pengisian')
    //   }
    // },
    saveChanges(event) {
      // Ctrl + Alt + N shortcut for add a new data
      if (event.ctrlKey && event.key === 'n') {
        this.newData(this.status)
      }
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault()
        this.updateAllData()
      }
    },
    fetch(keyword = '') {
      //
      this.emergencyLoading = false
      this.emergencyLoadingID = undefined
      this.approvedLoading = false
      this.approvedLoadingID = undefined

      this.stateOnAdd = false
      this.fetchLoading = true

      const url = this.resolveFetchUrl({ pagination: this.pagination })
      let http

      if (typeof url === 'string') {
        // http = useJwt.http.get(`${url}?lkps_table_id=${this.lkpsTableId}`)
      } else if (keyword === '' || keyword === null) {
        http = useJwt.http.get(url.url, { params: { ...url.query, keyword } })
      } else {
        //
        http = useJwt.http.get(url.url, { params: { keyword } })
      }

      http
        .then((response) => {
          let number =
            this.pagination.currentPage * this.pagination.selectedAmount -
            this.pagination.selectedAmount +
            1
          this.data = this.resolveFetchResponse(response).map((list) => ({
            ...list,
            selected: false,
            ...(this.addPropToData ? this.addPropToData(list) : {}),
            number: number++,
            uuid: uuidv4(),
            onEdit: false
          }))

          try {
            if (this.isCalculationEnable === true) {
              const calc = response.data.calculation
              if (calc) {
                this.calculation = calc
              } else {
              }
            } else {
            }
          } catch (err) {}

          this.dataMaster = response.data.dataMaster
          this.pagination.totalItems = response.data.total_items
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            title: 'Bukan Kesalahan anda!',
            text: `${err.response.data.message}`,
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/pages/error.svg'),
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'error',
            confirmButtonText: 'OK',
            showCancelButton: false,
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-outline-danger ml-1'
            },

            buttonsStyling: false
          }).then(async (result) => {})
        })
        .finally(() => (this.fetchLoading = false))
    },
    newData(status) {
      this.stateOnAdd = true

      if (this.numberTemp === 0) {
        this.numberTemp = this.data.length
          ? this.data[this.data.length - 1].number + 1
          : 1
      } else {
        this.numberTemp += 1
      }

      const data = {
        number: this.numberTemp,
        id: 0,
        onEdit: true,
        uuid: uuidv4(),
        lkps_table_id: this.lkpsTableId,
        ...this.newDataTemplate
      }

      // TODO : REUSABLE REQUEST API SET INIT
      if (
        Object.values(this.fields).some(
          (field) =>
            field.key === 'dm_land_vehicle_id' ||
            field.columnKey === 'dm_land_vehicle_id'
        )
      ) {
        data.dm_land_vehicle = {
          name: ''
        }
      }
      if (
        Object.values(this.fields).some(
          (field) =>
            field.key === 'land_vehicle_id' ||
            field.columnKey === 'land_vehicle_id'
        )
      ) {
        data.land_vehicle = {
          plat_nopol: ''
        }
      }
      if (
        Object.values(this.fields).some(
          (field) =>
            field.key === 'dm_personal_id' ||
            field.columnKey === 'dm_personal_id'
        )
      ) {
        data.dm_personal = {
          full_name: ''
        }
      }
      if (
        Object.values(this.fields).some(
          (field) => field.key === 'agen_id' || field.columnKey === 'agen_id'
        )
      ) {
        data.agen = {
          name: ''
        }
      }
      if (
        Object.values(this.fields).some(
          (field) =>
            field.key === 'transportir_id' ||
            field.columnKey === 'transportir_id'
        )
      ) {
        data.transportir = {
          name: ''
        }
      }
      if (
        Object.values(this.fields).some(
          (field) =>
            field.key === 'delivery_route_id' ||
            field.columnKey === 'delivery_route_id'
        )
      ) {
        data.delivery_route = {
          last_route: ''
        }
      }

      this.data.unshift(data)

      const firstField = Object.keys(this.newDataTemplate).find(
        (field) => !field.includes('_id', 'id_', '_id_')
      )
      this.onSelectData({ item: data }, firstField)
    },
    updateAllData() {
      console.log('updateAllData')
      const fields = this.fields.map((field) => field.columnKey || field.key)
      console.log('fields: ', fields)
      const validNewData = this.data.filter((data) => data.id === 0)
      console.log('validNewData: ', validNewData)
      const validUpdateData = this.data.filter(
        (data) => data.onEdit && data.id != 0
      )
      console.log('validUpdateData: ', validUpdateData)

      // Create 8, 16, 18, 24, 32
      const newData = validNewData.map((data) => {
        const fieldFilter = fields.filter(
          (column) =>
            column !== 'group.name' &&
            column !== 'user.full_name' &&
            column !== 'user.phone' &&
            column !== 'created_at' &&
            column !== 'updated_at'
        )

        console.log('fieldFilter: ', fieldFilter)

        const isEmpty = fieldFilter.map((field) => !data[field])
        console.log('isEmpty: ', isEmpty)

        fieldFilter.map((field) => {
          console.log('fieldFilter.map: ', field)
        })

        // notifaction

        if (isEmpty.includes(true)) {
          console.log('isEmpty.includes(true)', isEmpty.includes(true))
          this.data = this.data.filter((d) => d.uuid != data.uuid)
        } else {
          console.log('isEmpty.includes(true) false', false)
          return useJwt.http.post(this.createUrl || this.apiEndpoint, data)
        }
      })

      // Update
      const updateData = validUpdateData.map((data) =>
        useJwt.http.put(
          this.updateUrl || `${this.apiEndpoint}/${data.id}`,
          data
        )
      )

      const submitData = (datas) => {
        if (datas.map((data) => !!data)) {
          Promise.all(datas)
            .then((response) => {
              this.loading = false
              response.map((res) => {
                if (res !== undefined) {
                  this.$alert({
                    title: res.data.message,
                    variant: 'success',
                    icon: 'CheckIcon'
                  })
                }
              })
              if (response.length) {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
              }
            })
            .catch((error) => {
              this.loading = false
              this.$alert({
                title: 'Telah terjadi kesalahan.',
                variant: 'danger',
                icon: 'XIcon'
              })
            })
        } else {
        }
      }

      submitData(newData)
      submitData(updateData)

      // this.resetStatus()
    },
    onUpdate(data) {
      const fields = this.fields.map((field) => field.key)
      const isEmpty = fields.every((field) => data.item[field] === '')

      if (data.item.id == 0) {
        if (isEmpty) {
          this.data = this.data.filter((d) => d.uuid != data.item.uuid)
        } else {
          const newData = this.data.filter((d) => d.id == 0)
          Promise.all(
            newData.map((data) =>
              useJwt.http.post(this.createUrl || this.apiEndpoint, data)
            )
          )
            .then((response) => {
              this.$alert({
                title: response[0].data.message,
                variant: 'success',
                icon: 'CheckIcon'
              })
              // this.resetStatus()
              this.$bvModal.hide('update-data')
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
            })
            .catch(() =>
              this.$alert({
                title: 'Telah terjadi kesalahan.',
                variant: 'danger',
                icon: 'XIcon'
              })
            )
        }
      } else if (isEmpty) {
        this.onDelete(data.item.id)
      } else {
        const editData = this.data.filter((d) => d.onEdit)
        Promise.all(
          editData.map((data) =>
            useJwt.http.put(
              this.updateUrl || `${this.apiEndpoint}/${data.id}`,
              data
            )
          )
        )
          .then((response) => {
            this.$alert({
              title: response[0].data.message,
              variant: 'success',
              icon: 'CheckIcon'
            })
            // this.resetStatus()
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
          })
          .catch(() =>
            this.$alert({
              title: 'Telah terjadi kesalahan.',
              variant: 'danger',
              icon: 'XIcon'
            })
          )
      }
    },
    onSelectData(data, field = null) {
      data.item.onEdit = true
      this.stateOnAdd = true
      this.$nextTick(() => {
        try {
          const input = this.$refs[`${field}-${data.item.uuid}`]
          if (input) {
            try {
              input.focus()
              input.select()
            } catch (err) {
              if ('activateMenu' in input) {
                input.activateMenu() // not work
              }
            }
          }
        } catch (err) {}
      })
    },
    onDelete(id, deleteByDeleteButton = false, event) {
      const deleteData = () =>
        useJwt.http
          .delete(this.deleteUrl || `${this.apiEndpoint}/${id}/0`)
          .then((response) => {
            this.$alert({
              title: response.data.message,
              variant: 'success',
              icon: 'CheckIcon'
            })
            // this.resetStatus()
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
          })
          .catch(() =>
            this.$alert({
              title: 'Telah terjadi kesalahan.',
              variant: 'danger',
              icon: 'XIcon'
            })
          )

      if (deleteByDeleteButton) {
        if (event.key == 'Delete') {
          deleteData()
        }
      } else {
        deleteData()
      }
    },
    sentNotificationApproved(item) {
      const mapPath = {
        FormType1: '/#/monitoring/jalur-darat/form/type-1',
        FormType2: '/#/monitoring/jalur-darat/form/type-2',
        FormType3: '/#/monitoring/jalur-darat/form/type-3'
      }
      const route = this.domain + mapPath[this.refSpecify]

      // TODO: set from backend
      const userData = getUserData()
      const group = userData.group?.name ?? ''

      const mapType = {
        TEM: 'light-success',
        PATRA: 'light-warning',
        PIL: 'light-info',
        SLB: 'light-danger'
      }
      const mapAction = {
        TEM: ' ( ✓ Approved )',
        PATRA: ' ( ✓ Approved )',
        PIL: ' ( ✓ Approved )',
        SLB: ' ( ✓ Approved )'
      }
      const mapTypeTag = {
        FormType1: 'T1',
        FormType2: 'T2',
        FormType3: 'T3',
        FormType4: 'L1',
        FormType5: 'L2',
        FormType6: 'L3'
      }

      let type = ''
      let action = ''
      try {
        type = mapType[group]
        action = mapAction[group]
      } catch (err) {
        type = 'light-primary'
        action = ' ( ✓ Approved )'
      }
      const title = userData.full_name
      const payloadNotification = {
        registrationToken: this.$store.state.app.tokenNotification,
        title,

        form_type: mapTypeTag[this.refSpecify],
        type,
        action,
        plat_no_pol: item.dm_land_vehicle?.plat_no_pol,
        driver_name: item.dm_personal.full_name,
        is_read: false,
        tag: mapTypeTag[this.refSpecify].includes('T')
          ? 'land_route'
          : 'water_route',
        url: route
        // group, // req.group_id
      }
      useJwt.http
        .post('notification', payloadNotification)
        .then((data) => {})
        .catch((err) => {})
    },
    async approveFormStatus(formStatus, id, item) {
      let result
      await useJwt.http
        .put(`form3_land_vehicle/form/status/${id}`, {
          qrcode_status: 'Available',
          form_status: formStatus,
          full_name: item.dm_personal?.full_name,
          schedule_in: item.schedule_in,
          plat_no_pol: item.dm_land_vehicle.plat_no_pol,
          uuid: uuidv4()
        })
        .then(async () => {
          result = true
          console.log('success approve')
        })
        .catch((anError) => {
          result = false
          console.log('error approve', anError)
        })
      return result
    },
    async onAprrovedToTrigger(status, id, item) {
      if (status === this.$valueBEpendingApprove) {
        if (
          this.$can('MI (LMP)', '') ||
          this.$can('DAS (Cementing)', '') ||
          this.$can('Facility (SLB)', '') ||
          this.$can('RPI', '') ||
          this.$can('ELNUSA (Cementing)', '') ||
          this.$can('SAL', '')
        ) {
          this.$swal({
            title: 'Are you sure?',
            text: `You will ${
              status === this.$valueBEapprove ? 'Un Approve' : 'Approved'
            } to this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Yes, ${
              status === this.$valueBEapprove ? 'Un Approve' : 'Approved'
            } it!`,
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.approvedLoading = true
              this.approvedLoadingID = id
              const dataStatus = `${
                status === this.$valueBEapprove
                  ? this.$valueBEpendingApprove
                  : this.$valueBEapprove
              }`
              const isSuccess = await this.approveFormStatus(
                dataStatus,
                id,
                item
              )
              if (isSuccess) {
                this.$swal({
                  icon: 'success',
                  title: `${
                    status === this.$valueBEapprove ? 'Un Approved' : 'Approved'
                  }!`,
                  text: `Your form has been ${
                    status === this.$valueBEapprove ? 'Un Approved' : 'Approved'
                  }.`,
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                }).then(() => {
                  this.sentNotificationApproved(item)
                  if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                  this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
                })
              } else {
                this.approvedLoading = false
                this.approvedLoadingID = undefined
                this.$swal({
                  title: 'Failled',
                  text: `Your form is not ${
                    status !== this.$valueBEapprove ? 'Un Approved' : 'Approved'
                  } :)`,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
              }
            } else if (result.dismiss === 'cancel') {
              this.$swal({
                title: 'Cancelled',
                text: `Your form still on ${
                  status !== this.$valueBEapprove
                    ? 'Pending Approve'
                    : 'Approved'
                } :)`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            }
          })
        } else {
          this.$swal({
            position: 'top-end',
            icon: 'warning',
            title: 'You dont have permission to approve',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        }
      } else {
        if (this.$store.state.app.roleName === 'viewer') {
          this.$swal({
            position: 'top-end',
            icon: 'warning',
            title: 'You dont have permission',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
          return
        }
        this.$swal({
          position: 'top-end',
          icon: 'info',
          title: 'Your form has been approved',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
    },
    async onInOutToTrigger(id, item) {
      if (item.form_status === 'Pending Approve') {
        this.$swal({
          title: 'Terjadi Masalah?',
          text: `Form ini belum di approve oleh Admin ${item.group.name}`,
          icon: 'error',
          showCancelButton: false,
          // timer: 1500,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        })
        return
      }
      if (item.qrcode_status === 'Expired') {
        this.$swal({
          title: 'Terjadi Masalah?',
          text: 'Form ini sudah expired ( 1x24 Jam). Silahkan membuat form baru.',
          icon: 'error',
          showCancelButton: false,
          // timer: 1500,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        })
        return
      }
      if (item.clock_in_time === null && item.clock_out_time === null) {
        this.$swal({
          title: 'Are you sure?',
          text: 'Yakin ingin melakukan Clock IN',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Clock IN',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async (result) => {
          if (result.value) {
            this.emergencyLoading = true
            this.emergencyLoadingID = id
            const payloadNotification = {
              registrationToken: this.$store.state.app.tokenNotification
            }
            await useJwt.http
              .put(
                `${this.apiEndpoint}/form/clock_in/${id}`,
                payloadNotification
              )
              .then(async () => {
                await this.$swal({
                  title: 'Good job!',
                  text: 'Berhasil melakukan Clock IN',
                  icon: 'success',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                }).then(async () => {
                  // await this.fetch()
                })
              })
              .catch((e) => {
                this.emergencyLoading = false
                this.emergencyLoadingID = undefined
                this.$swal({
                  title: 'Failled',
                  text: `Tidak Berhasil melakukan Clock IN ${e}`,
                  icon: 'error',
                  // timer: 1500,
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
              })
          }
        })
        return
      }
      if (item.clock_in_time !== null && item.clock_out_time !== null) {
        this.$swal({
          title: 'Terjadi Masalah?',
          text: 'Form ini sudah digunakan Clock IN & Clock OUT ( One Time Pass )',
          icon: 'error',
          showCancelButton: false,
          // timer: 1500,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        })
        return
      }
      // clock out
      this.$swal({
        title: 'Are you sure?',
        text: 'Yakin ingin melakukan Clock OUT',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Clock OUT',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          this.emergencyLoading = true
          this.emergencyLoadingID = id
          const payloadNotification = {
            registrationToken: this.$store.state.app.tokenNotification
          }
          await useJwt.http
            .put(
              `${this.apiEndpoint}/form/clock_out/${id}`,
              payloadNotification
            )
            .then(async () => {
              await this.$swal({
                title: 'Good job!',
                text: 'Berhasil melakukan Clock OUT',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              }).then(async () => {
                // await this.fetch()
              })
            })
            .catch((e) => {
              this.emergencyLoading = false
              this.emergencyLoadingID = undefined
              this.$swal({
                title: 'Failled',
                text: `Tidak Berhasil melakukan Clock OUT ${e}`,
                icon: 'error',
                // timer: 1500,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            })
        }
      })
    },
    async onDeleteToTrigger() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          const isSuccess = await this.onDeleteToSelected()
          if (isSuccess) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Your file has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(() => this.fetch(), 300)
          } else {
            this.$swal({
              title: 'Failled',
              text: 'Your file is not deleted :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          }
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your imaginary file is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        }
      })
    },
    async onDeleteToSelected() {
      this.stateOnAdd = false

      const deletedData = this.data
        .filter((data) => data.selected)
        .map((data) => useJwt.http.delete(`${this.apiEndpoint}/${data.id}/0`))
      let isSuccess = false
      await Promise.all(deletedData)
        .then(async (response) => {
          isSuccess = true
          this.deleteMode = false
          // this.$alert({ title: response[0].data.message, variant: 'success', icon: 'CheckIcon' })
          // this.resetStatus()
        })
        .catch(() => {
          isSuccess = false
        })
      return isSuccess
    },
    onUpdateStatus(status) {
      this.stateOnAdd = false

      this.dataMaster.status = status

      const userData = getUserData()
      const department = userData.departments.length
        ? userData.departments[0]
        : null
      const programStudi = department ? department.program_studi : ''
      const shortName = department ? department.lkps_program.short_name : ''

      useJwt.http
        .put(`lkps_table/${this.dataMaster.id}`, {
          status: this.dataMaster.status,
          ts: this.dataMaster.ts,
          program_studi: programStudi,
          short_name: shortName
        })
        .then((response) =>
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon'
          })
        )
        .catch(() =>
          this.$alert({
            title: 'Telah terjadi kesalahan.',
            variant: 'danger',
            icon: 'XIcon'
          })
        )
      // this export mandatory lkps profile data
      if (this.singleExport && status === this.singleExport) {
        useJwt.http
          .get(`${this.dataMaster.list_lkps_table.helper_model}/submit/excel`, {
            params: {
              sort: 'ASC',
              lkps_table_id: `${this.dataMaster.id}`,
              ts: this.dataMaster.ts
            }
          })
          .then((response) =>
            this.$alert({
              title: response.data.message,
              variant: 'success',
              icon: 'CheckIcon'
            })
          )
          .catch((e) =>
            this.$alert({
              title: `Telah terjadi kesalahan. ${e}`,
              variant: 'danger',
              icon: 'XIcon'
            })
          )
      }
    },
    isColumnEditable(data) {
      return (
        data.item.onEdit &&
        this.fields.some(
          (field) => field.key == data.field.key && field.fillable !== false
        )
      )
    },
    toggleSelectedAll(checked) {
      this.data = this.data.map((data) => {
        data.selected = checked
        return data
      })
    },
    showSelectModal(data) {
      this.selectModal.options = []
      this.$bvModal.show(`${this.refSpecify}_select-modal`)

      if ('options' in data.field.input) {
        this.selectModal.options = data.field.input.options
      } else {
        this.selectModal.optionsApiEndpoint =
          data.field.input.optionsApiEndpoint
      }

      const value = this.resolveObjectKey(
        data.field.columnKey || data.field.key,
        data.item
      )
      if (data.field.input.type === 'date') {
        const { format } = data.field.input
        if (moment(value, format, true).isValid()) {
          this.selectModal.value = moment(value, format).format(
            data.field.input.format
          )
        }
      } else {
        this.selectModal.value = this.resolveObjectKey(
          data.field.columnKey || data.field.key,
          data.item
        )
        if (
          data.field.input.boolean === true &&
          data.item[data.field.key] === 1
        ) {
          this.selectModal.value = '✓'
        }
        if (
          data.field.input.boolean === true &&
          data.item[data.field.key] === 0
        ) {
          this.selectModal.value = '✕'
        }
      }
      this.selectModal.boolean = data.field.input.boolean
      this.selectModal.type = data.field.input.type
      this.selectModal.uuid = data.item.uuid
      this.selectModal.field = data.field
      this.selectModal.label = data.field.label
      this.selectModal.reduce = data.field.input.reduce
    },
    fetchOptions(keyword, loading, field) {
      if (this.selectModal.optionsApiEndpoint) {
        clearTimeout(this.timeoutDebounce)
        if (keyword.length >= 1) {
          this.timeoutDebounce = setTimeout(() => {
            loading(true)
            useJwt.http
              .get(this.selectModal.field.input.optionsApiEndpoint, {
                params: { keyword, single_search: true }
              })
              .then((response) => {
                this.selectModal.options =
                  this.selectModal.field.input.resolveOptionsResponse(response)
              })
              .finally(() => this.$forceUpdate())
              .finally(() => loading(false))
          }, 300)
        }
      }
    },

    fetchDataFromIDSelectModal(
      IDSelect,
      uriAPI,
      path,
      unique1 = null,
      unique2 = null
    ) {
      return useJwt.http
        .get(`${uriAPI}/${IDSelect}`)
        .then((response) => {
          if (response.data[`${uriAPI}s`]) {
            let uniqueValue1 = null
            let uniqueValue2 = null
            let concenateUnique = null
            let result = null

            if (unique1 !== null) {
              uniqueValue1 = `, ${unique1} : ${
                response.data[`${uriAPI}s`][`${unique1}`]
              }`
            }
            if (unique2 !== null) {
              uniqueValue2 = `, ${unique2} : ${
                response.data[`${uriAPI}s`][`${unique2}`]
              }`
            }

            concenateUnique = `${uniqueValue1 ?? ''}${uniqueValue2 ?? ''}`

            const { id: value } = response.data[`${uriAPI}s`]
            const label = response.data[`${uriAPI}s`][`${path[1]}`]
            result = `${label}${concenateUnique}`

            if (label !== undefined) {
              this.selectModal.options = [{ label: result, value }]
            } else {
              this.selectModal.options = [
                {
                  label: null,
                  value: null
                }
              ]
            }
          }
        })
        .finally(() => this.$forceUpdate())
    },

    fetchMatkulDetail(matkulId) {
      return useJwt.http
        .get(`matkul/${matkulId}`)
        .then((response) => {
          if (response.data.matkuls) {
            const { full_name: label, id: value } = response.data.matkuls
            this.selectModal.options = [{ label, value }]
          }
        })
        .finally(() => this.$forceUpdate())
    },
    convertData(datas) {
      const convertedData = datas.map((data) => {
        Object.keys(data).forEach((key) => {
          const field = this.fields.find((f) => f.key == key)
          if (field) {
            if (field.input.type == 'date') {
              const value = moment(data[key]).format(field.input.format)
              // data[key] = moment(data[key]).format(field.input.format)
              if (value == 'Invalid date') {
                data[key] = moment(data[key], field.input.format).format(
                  field.input.format
                )
              } else {
                data[key] = value
              }
            }
          }
        })
        return data
      })

      return convertedData
    },
    beforeSentDate(data) {
      if (data.field.input.type === 'date') {
        //
        const value = moment(
          data.item[data.field.columnKey || data.field.key]
        ).format(data.field.input.format)
        if (value == 'Invalid date') {
          return moment(
            data.item[data.field.columnKey || data.field.key],
            data.field.input.format
          ).format(data.field.input.format)
        }
        return value

        // eslint-disable-next-line no-else-return
      } else {
        try {
          // force optionsApiEndpoint show label instead of value on select
          const value = data.item[data.field.columnKey || data.field.key]
          const text = data.field.input.options.find(
            (option) => option.value === value
          )
          //
          return text.label
        } catch (error) {
          // force optionsFromApi
          return this.resolveObjectKey(data.field.key, data.item)
        }
      }
    },
    removeParentElement() {
      try {
        const fragment = document.createDocumentFragment()
        var element = this.$refs.removeJustThis
        element.forEach((element) => {
          while (element.firstChild) {
            fragment.appendChild(element.firstChild)
          }
          element.parentNode.replaceChild(fragment, element)
        })

        var element = this.$refs.removeJustThisEnd
        while (element.firstChild) {
          fragment.appendChild(element.firstChild)
        }
        element.parentNode.replaceChild(fragment, element)
      } catch (err) {}
    },
    resolveObjectKey(path, obj) {
      return path
        .split('.')
        .reduce((prev, curr) => (prev ? prev[curr] : null), obj)
    },
    selectModalOpen() {
      this.$nextTick(() => {
        if (this.selectModal.type === 'select') {
          if ('requestAPI' in this.selectModal.field.input) {
            const allowFetch = ['allow'].includes(
              this.selectModal.field.input.requestAPI
            )
            const uriAPI = this.selectModal.field.input.optionsApiEndpoint
            const { unique1, unique2 } = this.selectModal.field.input
            const path = this.selectModal.field.key.split('.')
            if (allowFetch) {
              if (this.selectModal.value) {
                this.fetchDataFromIDSelectModal(
                  this.selectModal.value,
                  uriAPI,
                  path,
                  unique1,
                  unique2
                )
              } else {
              }
            } else {
              // without ID Data
            }
          }
        }
      })
    },

    tableDateFormatter(value, format) {
      let valueDate
      try {
        if (moment(value, 'YYYY-MM-DD', true).isValid()) {
          valueDate = moment(value, 'YYYY-MM-DD').format(format)
        } else {
          valueDate = moment(value, 'YYYY-MM-DDTHH:mm:ssZ').format(format)
        }
      } catch (err) {
        valueDate = moment(valueDate, format).format(format)
      }
      return valueDate
    },
    tableTimeFormatter(value, format) {
      let valueDate
      try {
        if (moment(value, 'HH:mm:ss', true).isValid()) {
          valueDate = moment(value, 'HH:mm:ss').format(format)
        } else {
          valueDate = moment(value, 'YYYY-MM-DDTHH:mm:ssZ').format(format)
        }
      } catch (err) {
        valueDate = moment(valueDate, format).format(format)
      }
      return valueDate
    }
  }
}
</script>
<style>
table#table-transition .flip-list-move {
  transition: transform 0.4s;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
