var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    class: _vm.disableEdit ? '' : 'py-1',
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center mb-2 mb-sm-0",
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-sm-end",
    attrs: {
      "sm": "8"
    }
  }, [_vm.deleteMode ? [_c('b-button', {
    staticClass: "border-danger mr-1",
    style: {
      backgroundColor: 'white !important'
    },
    attrs: {
      "variant": "light"
    },
    on: {
      "click": function click() {
        _vm.deleteMode = false;
        _vm.stateOnAdd = false;
      }
    }
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Batal")])]), _c('b-button', {
    attrs: {
      "id": "konfirmasi-hapus-data",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.onDeleteToTrigger();
      }
    }
  }, [_vm._v(" Hapus ")])] : [_c('b-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.stateOnAdd && _vm.disableEdit === false || _vm.disableEdit === null,
      expression: "\n            (!stateOnAdd && disableEdit === false) || disableEdit === null\n          "
    }],
    staticClass: "mr-1",
    attrs: {
      "id": "hapus-data",
      "disabled": _vm.forceDisableCrud,
      "variant": "danger"
    },
    on: {
      "click": function click() {
        _vm.deleteMode = true;
        _vm.stateOnAdd = false;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TrashIcon"
    }
  })], 1), _vm.disableEdit === false || _vm.disableEdit === null ? _c('b-button', {
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "tambah-data",
      "disabled": _vm.forceDisableCrud,
      "variant": _vm.stateOnAdd ? 'warning' : 'success'
    },
    on: {
      "click": function click($event) {
        return _vm.newData(_vm.status);
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.stateOnAdd ? 'Klik Tambah Data Lagi' : 'Tambah Data') + " ")], 1)])]) : _vm._e(), _c('b-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.stateOnAdd,
      expression: "stateOnAdd"
    }],
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "save-data",
      "disabled": _vm.forceDisableCrud,
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.saveButton();
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "SaveIcon"
    }
  }), _vm._v(" Simpan Data ")], 1)])]), _c('b-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.stateOnAdd,
      expression: "stateOnAdd"
    }],
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "cancel-data",
      "disabled": _vm.forceDisableCrud,
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.cancelSaveButton();
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "SaveIcon"
    }
  }), _vm._v(" Batalkan ")], 1)])])]], 2)], 1), _c('section', {
    attrs: {
      "id": "card-actions"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "title": "Advance Search",
      "action-collapse": "",
      "collapsed": true
    }
  }, [_c('template', {
    slot: "description"
  }, [_c('span', [_vm._v("Click on ")]), _c('feather-icon', {
    attrs: {
      "icon": "ChevronDownIcon"
    }
  }), _c('span', [_vm._v(" to see advance search collapse in action.")])], 1), _c('b-card-text', [_c('b-row', _vm._l(_vm.advanceFieldSearch(), function (dataField, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "4"
      }
    }, ['searchModel' in dataField ? _c('b-form-group', [dataField.search.type !== 'checkbox' ? _c('div', [_c('label', [_vm._v(_vm._s(dataField.label) + ":")]), _c('b-form-input', {
      staticClass: "d-inline-block",
      attrs: {
        "placeholder": dataField.searchModel === 'group.name' ? 'all data , already group by hulu migas' : _vm.$t('search'),
        "type": dataField.search.type,
        "disabled": dataField.searchModel === 'group.name' ? true : false
      },
      on: {
        "input": function input($event) {
          return _vm.advanceSearch($event, dataField[dataField.searchModel]);
        }
      },
      model: {
        value: dataField[dataField.searchModel],
        callback: function callback($$v) {
          _vm.$set(dataField, dataField.searchModel, $$v);
        },
        expression: "dataField[dataField.searchModel]"
      }
    })], 1) : _c('div', [_c('label', [_vm._v(_vm._s(dataField.label) + ":")]), _c('v-select', {
      attrs: {
        "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
        "options": dataField.search.option,
        "label": "title"
      },
      on: {
        "input": _vm.advanceSearch
      },
      model: {
        value: dataField[dataField.searchModel],
        callback: function callback($$v) {
          _vm.$set(dataField, dataField.searchModel, $$v);
        },
        expression: "dataField[dataField.searchModel]"
      }
    })], 1)]) : _vm._e()], 1);
  }), 1)], 1)], 2)], 1), _c('b-card', {
    attrs: {
      "body-class": "p-0"
    }
  }, [_c('div', {
    staticClass: "p-2",
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "5",
      "lg": "9"
    }
  }, [_c('h3', {
    staticClass: "mb-2 mb-md-0"
  }, [_vm._v(" " + _vm._s(_vm.subTitle) + " ")]), _c('span', [_vm._v("Halaman hanya dapat dilihat oleh : ")]), _c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "DatabaseIcon"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.infoPageAbility))])], 1), _c('b-col', [_vm.enableCreateForm !== null && (_vm.$can('manage', 'form1') || _vm.$can('manage', 'form2') || _vm.$can('manage', 'form3')) && _vm.$store.state.app.roleName !== 'viewer' ? _c('b-button', {
    attrs: {
      "variant": "info",
      "to": {
        name: _vm.enableCreateForm
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "FolderPlusIcon"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Buat Form")])], 1) : _vm._e()], 1), _c('b-col', [_vm.enableCreateForm !== null && _vm.$can('EXPORT', 'DATA') ? _c('b-button', {
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.advanceSetData();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "FolderPlusIcon"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Export XLSX")])], 1) : _vm._e()], 1)], 1)], 1), _vm.fetchLoading ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "info"
    }
  }) : _vm._e(), _c('b-table', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary",
      modifiers: {
        "hover": true,
        "v-primary": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: _vm.disableEdit == false || _vm.disableEdit == null ? 'Untuk Mengubah Data, Klik 2x Pada Kolomnya' : '',
      expression: "\n        disableEdit == false || disableEdit == null\n          ? 'Untuk Mengubah Data, Klik 2x Pada Kolomnya'\n          : ''\n      ",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    ref: _vm.refSpecify || 'YoTable',
    staticClass: "m-0",
    staticStyle: {},
    attrs: {
      "id": "table-transition",
      "fields": _vm.customedFields,
      "no-border-collapse": true,
      "items": _vm.data,
      "tbody-tr-class": _vm.rowClass,
      "tbody-transition-props": _vm.transProps,
      "sticky-header": "700px",
      "responsive": "",
      "hover": "",
      "caption-top": "",
      "show-empty": "",
      "primary-key": "number",
      "bordered": ""
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-0 py-2 text-center"
        }, [_vm._v("Tidak ada data")])];
      },
      proxy: true
    }, {
      key: "bottom-row",
      fn: function fn(data) {
        return _vm._l(data.fields, function (field, i) {
          return _c('td', {
            key: i
          }, [i !== 0 ? _c('span', [_c('strong', [_vm._v(_vm._s(_vm.calculation[field.key]))])]) : _vm._e(), i === 1 ? _c('span', [_vm._v(" Jumlah Data : " + _vm._s(_vm.pagination.totalItems) + " ")]) : _vm._e()]);
        });
      }
    }, _vm.multipleHeader ? {
      key: "thead-top",
      fn: function fn(data) {
        return [_c('b-tr', [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleHeader.startFrom
          }
        }), _vm._l(_vm.multipleHeader.content, function (header, index) {
          return _c('multiple-header', {
            key: index,
            ref: "removeJustThis",
            refInFor: true
          }, [_c('b-th', {
            class: header.align,
            attrs: {
              "variant": "primary",
              "colspan": header.span
            },
            domProps: {
              "textContent": _vm._s(header.value)
            }
          })], 1);
        }), _vm.multipleHeader.noEnd == false ? _c('multiple-header-end', {
          ref: "removeJustThisEnd"
        }, [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleHeader.endFrom
          }
        })], 1) : _vm._e()], 2)];
      }
    } : _vm.multipleTripleHeader ? {
      key: "thead-top",
      fn: function fn(data) {
        return [_c('b-tr', [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleTripleHeader.startFromFirst
          }
        }), _vm._l(_vm.multipleTripleHeader.contentFirst, function (header, index) {
          return _c('multiple-header', {
            key: index,
            ref: "removeJustThis",
            refInFor: true
          }, [_c('b-th', {
            class: header.align,
            attrs: {
              "variant": "primary",
              "colspan": header.span
            },
            domProps: {
              "textContent": _vm._s(header.value)
            }
          })], 1);
        }), _vm.multipleTripleHeader.noEndFirst == false ? _c('multiple-header-end', {
          ref: "removeJustThisEnd"
        }, [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleTripleHeader.endFromFirst
          }
        })], 1) : _vm._e()], 2), _c('b-tr', [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleTripleHeader.startFromSecond
          }
        }), _vm._l(_vm.multipleTripleHeader.contentSecond, function (header, index) {
          return _c('multiple-header', {
            key: index,
            ref: "removeJustThis",
            refInFor: true
          }, [_c('b-th', {
            class: header.align,
            attrs: {
              "variant": "primary",
              "colspan": header.span
            },
            domProps: {
              "textContent": _vm._s(header.value)
            }
          })], 1);
        }), _vm.multipleTripleHeader.noEndSecond == false ? _c('multiple-header-end', {
          ref: "removeJustThisEnd"
        }, [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleTripleHeader.endFromSecond
          }
        })], 1) : _vm._e()], 2)];
      }
    } : null, {
      key: "head(select)",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          on: {
            "change": _vm.toggleSelectedAll
          }
        })];
      },
      proxy: true
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [data.field.key == 'number' ? _c('div', [_c('p', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(data.item.number)
          }
        })]) : data.field.key == 'select' && _vm.deleteMode ? _c('div', [_c('b-form-checkbox', {
          model: {
            value: data.item.selected,
            callback: function callback($$v) {
              _vm.$set(data.item, "selected", $$v);
            },
            expression: "data.item.selected"
          }
        })], 1) : data.field.key === 'segmen' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.formStatusVariant(data.item.segmen)
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "MousePointerIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(data.item.segmen)
          }
        })], 1)], 1) : data.field.key === 'form_status' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.formStatusVariant(data.item.form_status)
          },
          on: {
            "click": function click($event) {
              _vm.approvedLoadingID === data.item.id ? null : _vm.onAprrovedToTrigger(data.item.form_status, data.item.id, data.item);
            }
          }
        }, [_vm.approvedLoading && _vm.approvedLoadingID === data.item.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "MousePointerIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(_vm.formStatus(data.item.form_status))
          }
        })], 1)], 1) : data.field.key === 'bongkar_no_doc' ? _c('div', [data.item.keg_bongkar === true ? _c('b-badge', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-data-preview",
            modifiers: {
              "modal-data-preview": true
            }
          }],
          attrs: {
            "pill": "",
            "variant": "info"
          },
          on: {
            "click": function click() {
              _vm.previewID = data.item.id;
              _vm.previewAPI = 'bongkar';
              _vm.previewTitle = "No Document (Bongkar) : ".concat(data.item.bongkar_no_doc || 'Harap Diisi', " , Asal Barang : ").concat(data.item.bongkar_asal_brg || 'Pengguna Tidak Mengisi');
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PackageIcon",
            "size": "55"
          }
        }), _c('span')], 1) : _vm._e(), _c('span', {
          staticClass: "mb-0 font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.bongkar_no_doc) + " ")])], 1) : data.field.key === 'muat_no_doc' ? _c('div', [data.item.keg_muat === true && !data.item.muat_no_doc ? _c('div', [data.item.keg_muat === true ? _c('b-badge', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-data-preview",
            modifiers: {
              "modal-data-preview": true
            }
          }],
          staticClass: "mr-25",
          attrs: {
            "pill": "",
            "variant": "info"
          },
          on: {
            "click": function click() {
              _vm.previewID = data.item.id;
              _vm.previewAPI = 'muat';
              _vm.previewTitle = "No Document (Muat) : ".concat(data.item.muat_no_doc || 'Harap Diisi', " , Tujuan : ").concat(data.item.muat_tujuan || 'Pengguna Tidak Mengisi');
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PackageIcon",
            "size": "55"
          }
        }), _c('span')], 1) : _vm._e(), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalUpdateFORM(data.item.id, 'No Dokumen (M)', data.item.muat_no_doc, data.field.key);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AlertTriangleIcon"
          }
        }), _c('span', {
          staticClass: "mb-0 font-weight-bold"
        }, [_vm._v(" Harap Diisi ")])], 1)], 1) : _c('div', [data.item.keg_muat === true ? _c('b-badge', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-data-preview",
            modifiers: {
              "modal-data-preview": true
            }
          }],
          attrs: {
            "pill": "",
            "variant": "info"
          },
          on: {
            "click": function click() {
              _vm.previewID = data.item.id;
              _vm.previewAPI = 'muat';
              _vm.previewTitle = "No Document (Muat) : ".concat(data.item.muat_no_doc || 'Harap Diisi', " , Tujuan : ").concat(data.item.muat_tujuan || 'Pengguna Tidak Mengisi');
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PackageIcon",
            "size": "55"
          }
        }), _c('span')], 1) : _vm._e(), _c('span', {
          staticClass: "mb-0 font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.muat_no_doc) + " ")])], 1)]) : data.field.key === 'form_expired_at' ? _c('div', [_vm.tableDateFormatter(data.unformatted, data.field.input.format) === '5 May 5555 - 05:05 AM' ? _c('p', [_vm._v(" No Expired ( kendaraan telah masuk ) ")]) : _c('p', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(_vm.tableDateFormatter(data.unformatted, data.field.input.format))
          }
        })]) : data.field.key === 'emergency_action' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.formQRCodeVariant(data.item)
          },
          on: {
            "click": function click($event) {
              _vm.emergencyLoadingID === data.item.id ? null : _vm.onInOutToTrigger(data.item.id, data.item);
            }
          }
        }, [_vm.emergencyLoading && _vm.emergencyLoadingID === data.item.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "WatchIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(_vm.formQRStatus(data.item))
          }
        })], 1)], 1) : data.field.key === 'action_qrcode' ? _c('div', [_c('b-badge', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "pill": "",
            "variant": data.item.qrcode ? 'primary' : 'danger'
          },
          on: {
            "click": function click($event) {
              return _vm.showCustomImage(data.item.qrcode, data.item.dm_personal.full_name, data.item.schedule_in);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("QRCode")])], 1)], 1) : data.field.key === 'qrcode_status' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.qrcodeStatusVariant(data.item.qrcode_status)
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "WatchIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(data.item.qrcode_status)
          }
        })], 1)], 1) : data.field.key === 'action_form' ? _c('div', [_c('b-dropdown', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "flat-primary",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [!_vm.$can('manage', 'qrcode') && _vm.$store.state.app.roleName !== 'viewer' ? _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'monitoring-jalur-darat-form-type-3-wizard',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "TruckIcon"
          }
        }), _c('span', {
          staticClass: "text-danger align-middle ml-50"
        }, [_vm._v("Lakukan Revisi")])], 1) : _vm._e(), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-data-history",
            modifiers: {
              "modal-data-history": true
            }
          }],
          on: {
            "click": function click() {
              return _vm.historyID = data.item.id;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "text-warning",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', {
          staticClass: "text-warning align-middle ml-50"
        }, [_vm._v("History Data")])], 1), !_vm.$can('manage', 'qrcode') && _vm.$store.state.app.roleName !== 'viewer' ? _c('b-dropdown-item', [_c('feather-icon', {
          staticClass: "text-info",
          attrs: {
            "icon": "PackageIcon"
          }
        }), _c('span', {
          staticClass: "text-info align-middle ml-50"
        }, [_vm._v("Buat Operasional")])], 1) : _vm._e()], 1)], 1) : data.field.key === 'group.name' ? _c('div', [_c('b-badge', {
          attrs: {
            "href": "#",
            "variant": "info"
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LinkIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(data.unformatted === 'SLB' ? 'Hulu Migas' : '')
          }
        })], 1)], 1) : _c('div', {
          style: {
            cursor: _vm.isColumnEditable(data) ? 'pointer' : 'text'
          },
          on: {
            "dblclick": function dblclick($event) {
              _vm.forceDisableCrud == true ? '' : _vm.onSelectData(data);
            }
          }
        }, [data.item.onEdit && _vm.isColumnEditable(data) ? _c('div', [_vm.$slots[data.field.columnKey || data.field.key] || _vm.$scopedSlots[data.field.columnKey || data.field.key] ? _vm._t(data.field.key, null, {
          "column": {
            data: data,
            onUpdate: _vm.onUpdate,
            onDelete: _vm.onDelete
          }
        }) : _vm._e(), _c('div', [data.field.input != undefined ? _c('div', [['select', 'date'].includes(data.field.input.type) ? _c('b-form-input', {
          attrs: {
            "readonly": "",
            "value": _vm.beforeSentDate(data),
            "disabled": data.field.input.disabled,
            "state": data.field.columnKey === 'dm_land_vehicle_id' && data.item[data.field.columnKey || data.field.key] === 0 ? false : data.field.input.options && data.field.input.boolean ? true : true
          },
          on: {
            "click": function click($event) {
              return _vm.showSelectModal(data);
            },
            "keyup": [function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.shiftKey) { return null; }
              if ($event.ctrlKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }, function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.ctrlKey) { return null; }
              if ($event.shiftKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }]
          }
        }) : data.field.input.type == 'number' ? _c('b-form-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "number",
            "disabled": data.field.input.disabled,
            "placeholder": "".concat(data.item[data.field.columnKey || data.field.key] || data.field.placeholder),
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "data.item[data.field.columnKey || data.field.key]"
          }
        }) : data.field.input.type == 'tag' ? _c('b-form-tags', {
          attrs: {
            "input-id": "tag-".concat(data.uuid),
            "input-attrs": {
              'aria-describedby': 'tags-remove-on-delete-help'
            },
            "separator": ",",
            "placeholder": "1. DATA A, 2. DATA B",
            "remove-on-delete": "",
            "no-add-on-enter": ""
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "data.item[data.field.columnKey || data.field.key]"
          }
        }) : data.field.input.type == 'number-append' ? _c('b-input-group', {
          attrs: {
            "append": data.field.input.append
          }
        }, [_c('b-form-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "number",
            "required": "",
            "disabled": data.field.input.disabled,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true,
            "placeholder": "".concat(data.field.placeholder)
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                      data.item[data.field.columnKey || data.field.key]\n                    "
          }
        })], 1) : data.field.input.type == 'currency' ? _c('b-input-group', {
          attrs: {
            "append": data.field.input.append
          }
        }, [_c('currency-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "disabled": data.field.input.disabled,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true,
            "placeholder": "".concat(data.item[data.field.columnKey || data.field.key]),
            "options": {
              currency: 'IDR',
              locale: 'id-ID',
              currencyDisplay: 'hidden',
              autoDecimalDigits: false,
              precision: 2
            }
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                      data.item[data.field.columnKey || data.field.key]\n                    "
          }
        })], 1) : data.field.input.type == 'input-noarea' ? _c('b-form-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "text",
            "disabled": data.field.input.disabled,
            "placeholder": data.field.placeholder,
            "value": data.unformatted,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "data.item[data.field.columnKey || data.field.key]"
          }
        }) : _c('b-form-textarea', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "text",
            "disabled": data.field.input.disabled,
            "placeholder": data.field.placeholder,
            "value": data.unformatted,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "data.item[data.field.columnKey || data.field.key]"
          }
        })], 1) : _vm._e()])], 2) : _c('div', [data.unformatted !== '' ? _c('div', ['input' in data.field && 'boolean' in data.field.input && data.field.input.boolean === true ? _c('div', [_c('span', {
          domProps: {
            "textContent": _vm._s(data.unformatted == '1' ? '✓' : '✕')
          }
        })]) : data.field.key === 'user.full_name' ? _c('div', [_c('span', {
          domProps: {
            "textContent": _vm._s("".concat(data.item.user.full_name, " (").concat(data.item.user.perusahaan, ")"))
          }
        })]) : 'type' in data.field.input && data.field.input.type === 'date' ? _c('div', [_c('p', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(_vm.tableDateFormatter(data.unformatted, data.field.input.format))
          }
        })]) : 'type' in data.field.input && data.field.input.type === 'time' ? _c('div', [_c('p', {
          staticClass: "mb-0 font-weight-bold"
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(_vm.tableTimeFormatter(data.unformatted, data.field.input.format))
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": _vm.tableTimeFormatter(data.unformatted, data.field.input.format).toString().includes('PM') ? 'MoonIcon' : 'SunIcon'
          }
        })], 1)]) : data.field.input.type === 'tag' ? _c('div', [_c('b-form-tags', {
          attrs: {
            "input-id": "tag-".concat(data.uuid),
            "input-attrs": {
              'aria-describedby': 'tags-remove-on-delete-help'
            },
            "separator": ",",
            "placeholder": "",
            "disabled": "true",
            "remove-on-delete": "",
            "no-add-on-enter": ""
          },
          model: {
            value: data.unformatted,
            callback: function callback($$v) {
              _vm.$set(data, "unformatted", $$v);
            },
            expression: "data.unformatted"
          }
        })], 1) : _c('div', [_c('span', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(data.unformatted)
          }
        })])]) : _c('span', {
          domProps: {
            "textContent": _vm._s('-')
          }
        })])])];
      }
    }], null, true)
  }), _c('b-card-footer', [_c('b-row', {
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "mb-2 mb-sm-0",
    attrs: {
      "sm": "12",
      "md": "2"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.pagination.amountOptions
    },
    model: {
      value: _vm.pagination.selectedAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "selectedAmount", $$v);
      },
      expression: "pagination.selectedAmount"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-center justify-content-sm-end",
    attrs: {
      "sm": "12",
      "md": "2"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0",
    attrs: {
      "total-rows": _vm.pagination.totalItems,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item",
      "per-page": _vm.pagination.selectedAmount
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": _vm.refSpecify + '_select-modal',
      "title": "Pilihan",
      "body-class": "py-2",
      "hide-footer": "",
      "no-enforce-focus": true
    },
    on: {
      "show": _vm.selectModalOpen
    }
  }, [_c('label', {
    staticClass: "mb-1",
    attrs: {
      "for": "pilihan"
    }
  }, [_vm._v(_vm._s(_vm.selectModal.label))]), _c('b-form-group', [_vm.selectModal.type == 'select' ? _c('v-select', {
    ref: "select",
    attrs: {
      "id": "pilihan",
      "options": _vm.selectModal.options,
      "reduce": _vm.selectModal.reduce
    },
    on: {
      "search": function search(keyword, loading) {
        return _vm.fetchOptions(keyword, loading);
      }
    },
    model: {
      value: _vm.selectModal.value,
      callback: function callback($$v) {
        _vm.$set(_vm.selectModal, "value", $$v);
      },
      expression: "selectModal.value"
    }
  }, [_c('span', {
    attrs: {
      "slot": "no-options"
    },
    slot: "no-options"
  }, [_vm._v(_vm._s(this.$t('no_data')))])]) : _vm.selectModal.type == 'date' ? _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "static": "true",
      "config": _vm.dpconfig
    },
    model: {
      value: _vm.selectModal.value,
      callback: function callback($$v) {
        _vm.$set(_vm.selectModal, "value", $$v);
      },
      expression: "selectModal.value"
    }
  }) : _vm._e()], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "delete",
      "title": "Hapus",
      "cancel-variant": "secondary",
      "cancel-title": "Tidak",
      "ok-title": "Iya",
      "ok-variant": "danger",
      "body-class": "py-2",
      "footer-class": "d-flex justify-content-center",
      "centered": ""
    },
    on: {
      "ok": _vm.onDeleteToSelected
    }
  }, [_c('b-card-text', {
    staticClass: "text-center"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Hapus data ?")]), _c('br'), _vm._l(_vm.data.filter(function (data) {
    return data.selected;
  }), function (item) {
    return _c('div', {
      key: item.number
    }, [_vm._v(" # " + _vm._s(item.number) + ", ")]);
  })], 2)], 1), _c('b-modal', {
    attrs: {
      "id": _vm.refSpecify + '_update_form',
      "title": "Update Form | Harap Segera di lengkapi",
      "ok-title": "Update",
      "ok-variant": "success",
      "cancel-variant": "outline-secondary",
      "size": "lg"
    },
    on: {
      "ok": _vm.submitModalUpdateFORM
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": _vm.updateFormModal.label,
      "label-for": _vm.updateFormModal.label
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": _vm.updateFormModal.label,
      "placeholder": _vm.updateFormModal.label
    },
    model: {
      value: _vm.updateFormModal.value,
      callback: function callback($$v) {
        _vm.$set(_vm.updateFormModal, "value", $$v);
      },
      expression: "updateFormModal.value"
    }
  })], 1)], 1)], 1), _c('ExportXlsx', {
    attrs: {
      "is-export-sidebar-active": _vm.isExportSidebarActive,
      "api-genereate-excel": _vm.apiEndpoint + '/submit/excel',
      "backend-url": _vm.BACKEND_URL
    },
    on: {
      "update:isExportSidebarActive": function updateIsExportSidebarActive($event) {
        _vm.isExportSidebarActive = $event;
      },
      "update:is-export-sidebar-active": function updateIsExportSidebarActive($event) {
        _vm.isExportSidebarActive = $event;
      }
    }
  }), _vm.historyID ? _c('DataHistoryM', {
    ref: "modalhistory",
    attrs: {
      "id": "modal-data-history",
      "read-history-i-d": _vm.historyID,
      "ref-specify": _vm.refSpecify + '_history',
      "api-endpoint": _vm.apiEndpoint,
      "fields": _vm.fields.filter(function (e) {
        return e.key !== 'action_form' && e.key !== 'emergency_action';
      }),
      "disable-edit": _vm.disableEdit,
      "force-disable-crud": true
    }
  }) : _vm._e(), _vm.previewID ? _c('PreviewModal', {
    ref: "previewModal",
    attrs: {
      "id": "modal-data-preview",
      "title-tbl": _vm.previewTitle,
      "read-history-i-d": _vm.previewID,
      "ref-specify": _vm.refSpecify + '_preview',
      "api-endpoint": _vm.previewAPI,
      "fields": _vm.previewField,
      "disable-edit": _vm.disableEdit,
      "force-disable-crud": true
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }